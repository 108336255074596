import { forwardRef, memo, type ReactNode, useContext, useEffect, type Component } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import { type BoxProps } from '@mui/material/Box';
// EmPath UI Components
import type { BreadcrumbItem } from '@empathco/ui-components/src/types';
import { type AppBarVariant, AppBarVariantProp, APPBAR_NONE } from '@empathco/ui-components/src/elements/AppBar';
import AppScreen from '@empathco/ui-components/src/elements/AppScreen';
// local imports
import { DataContext } from '../context';
import Header from '../widgets/Header';

type ScreenProps = {
  children?: ReactNode | ReactNode[];
  admin?: boolean;
  splash?: boolean;
  fullHeight?: boolean;
  dashboard?: boolean;
  breadcrumbs?: BreadcrumbItem[] | null;
  appbar?: AppBarVariant;
  footer?: ReactNode | ReactNode[];
  withSettings?: boolean;
  className?: string;
};

const ScreenPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  // attributes
  admin: PropTypes.bool,
  splash: PropTypes.bool,
  fullHeight: PropTypes.bool,
  dashboard: PropTypes.bool,
  breadcrumbs: PropTypes.array,
  appbar: AppBarVariantProp,
  footer: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  withSettings: PropTypes.bool,
  className: PropTypes.string
};

const Screen = forwardRef<Component<BoxProps>, ScreenProps>(({
  children,
  admin = false,
  splash = false,
  fullHeight = false,
  dashboard = false,
  breadcrumbs,
  appbar = 'full',
  footer,
  withSettings = false,
  className
}, ref) => {
  const { requireSettings } = useContext(DataContext);

  useEffect(() => {
    if (withSettings) requireSettings?.();
  }, [withSettings, requireSettings]);

  return (
    <AppScreen
        ref={ref}
        splash={splash}
        fullHeight={fullHeight}
        dashboard={dashboard}
        appbar={appbar}
        className={className}
        header={appbar !== APPBAR_NONE && (
          <Header
              breadcrumbs={breadcrumbs}
              variant={appbar}
              admin={admin}
          />
        )}
        footer={footer}
    >
      {children}
    </AppScreen>
  );
});

Screen.displayName = 'Screen';

Screen.propTypes = ScreenPropTypes;

export default memo(Screen);
