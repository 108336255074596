import { memo, forwardRef, useCallback, useEffect, useMemo, useState, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import head from 'lodash/head';
import take from 'lodash/take';
import takeRight from 'lodash/takeRight';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import transform from 'lodash/transform';
import endsWith from 'lodash/endsWith';
import toString from 'lodash/toString';
import { useIntl } from 'react-intl';
// Material UI imports
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select, { type SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
// Material Icon imports
import HomeAccount from 'mdi-material-ui/HomeAccount';
// EmPath UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import { DEFAULT_MENU_LEFT, DEFAULT_MENU_RIGHT } from '@empathco/ui-components/src/helpers/menus';
// local imports
import { Manager } from '../graphql/types';
import { ManagerItem } from '../graphql/customTypes';
import ChainOfCommandItem from '../v3/ChainOfCommandItem';
// SCSS imports
import { root, placeholder } from '@empathco/ui-components/src/styles/modules/Lookup.module.scss';
import { paper, list, divider } from './ChainOfCommandSelector.module.scss';

const menuItemSx = { py: 0.375, pl: 1.25 };
const iconBtnSx = { mr: -1 };

function getManagersExpanded(uid: string, managers: Manager[]): Manager[] {
  // current user/supervisor chain of command (top manager at the beginning)
  let userCoC: ManagerItem[] = [];
  let userCocIdx = -1;
  let userIdx = (uid ? find(managers, ['id', uid]) : null)?.parent_idx ?? -1;
  if (userIdx >= 0) {
    // helper function
    const addDirectReports = (indices: number[]): ManagerItem[] => {
      const result: ManagerItem[] = [];
      forEach(indices, (idx: number) => {
        const expanded = userCocIdx === idx;
        result.push({ ...managers[idx], expanded }, ...expanded ? userCoC : []);
      });
      return result;
    };
    // iterate thru the chain of command of the current manager
    while (userIdx >= 0) {
      const { direct_reports_idx, parent_idx } = managers[userIdx];
      userCoC = addDirectReports(direct_reports_idx ?? []);
      userCocIdx = userIdx;
      userIdx = parent_idx ?? -1;
    }
    // add the top manager of the chain
    if (userCocIdx >= 0) {
      userCoC.unshift({
        ...managers[userCocIdx],
        expanded: true
      });
    }
  }
  // top managers + user/supervisor chain of command
  const managrs: ManagerItem[] = [];
  forEach(head(managers)?.siblings_idx ?? [], (idx) => {
    if (idx === userCocIdx) {
      managrs.push(...userCoC);
    } else {
      managrs.push({ ...managers[idx], expanded: false });
    }
  });
  return managrs;
}

type ChainOfCommandSelectorPlacement = 'left' | 'right';

type ChainOfCommandSelectorProps = {
  uid?: string | null;
  me?: string | null;
  leader?: string | null;
  delegate?: boolean | null;
  managers?: Manager[] | null;
  value: string;
  onChange: (id: string, hasTeams?: boolean, orgId?: number | null) => void;
  disabled?: boolean | null;
  expandable?: boolean | null;
  allowEmpty?: boolean | null;
  disableTopLevel?: boolean | null;
  placement?: ChainOfCommandSelectorPlacement;
  helperText?: ReactNode;
  error?: boolean;
};

const ChainOfCommandSelectorPropTypes = {
  // attributes
  uid: PropTypes.string,
  me: PropTypes.string,
  leader: PropTypes.string,
  delegate: PropTypes.bool,
  managers: PropTypes.array,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  expandable: PropTypes.bool,
  allowEmpty: PropTypes.bool,
  disableTopLevel: PropTypes.bool,
  placement: PropTypes.oneOf(['left', 'right']) as Validator<ChainOfCommandSelectorPlacement>,
  helperText: PropTypes.node as Validator<ReactNode>,
  error: PropTypes.bool
};

// eslint-disable-next-line complexity
const ChainOfCommandSelector = forwardRef<HTMLDivElement, ChainOfCommandSelectorProps>(({
  uid,
  me,
  leader,
  delegate = false,
  managers,
  value,
  onChange,
  disabled = false,
  expandable = false,
  allowEmpty = false,
  disableTopLevel = false,
  placement = 'right',
  helperText,
  error
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const [mngrs, setMngrs] = useState<ManagerItem[] | null>(expandable ? null : managers ?? null);
  const [meMngrs, setMeMngrs] = useState<ManagerItem[] | null>(null);
  const [meItem, setMeItem] = useState<ManagerItem | null>(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!expandable) {
      setMngrs(managers || null);
      setMeItem(null);
      setMeMngrs(null);
    } else if (!uid || !managers) {
      setMngrs(null);
      setMeItem(null);
      setMeMngrs(null);
    } else {
      const meItm = (me && find(managers, ['id', me])) ||
        (leader && (!me || leader !== me) && find(managers, ['id', leader])) ||
        null;
      setMeItem(meItm);
      setMeMngrs(meItm ? getManagersExpanded(meItm.id, managers) : null);
      setMngrs(getManagersExpanded(uid, managers));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // We do not need to track `uid` here, because when `managers` are loaded, `uid` already contains the initial value,
    // and we only need it for initialization; ignoring all subsequent changes to `uid` (it could change
    // if `manager_id` is saved to user settings).
    managers, expandable, me, leader
  ]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleMe = useCallback(() => {
    if (meItem && meMngrs) {
      setMngrs(meMngrs);
      onChange(meItem.id, size(meItem.direct_reports_idx) >= 1, meItem.org_id);
    }
  }, [meItem, meMngrs, onChange]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      const id = toString(event?.target?.value);
      const manager = expandable ? find(mngrs, ['id', id]) : undefined;
      onChange(id, manager ? size(manager.direct_reports_idx) >= 1 : false, manager?.org_id);
    },
    [expandable, mngrs, onChange]
  );

  const handleExpand = useCallback((id: string) => {
    const clickedIdx = findIndex(mngrs, ['id', id]);
    if (clickedIdx >= 0) {
      const mngr = (mngrs && mngrs[clickedIdx]) || {} as ManagerItem;
      const { expanded, level, direct_reports_idx } = mngr;
      const count = size(mngrs);
      if (expanded) {
        const lastIdx = mngrs
          ? findIndex(mngrs, (mgr) => (mgr?.level ?? 0) <= (level ?? 0), (clickedIdx ?? 0) + 1)
          : -1;
        setMngrs([
          ...clickedIdx > 0 ? take(mngrs, clickedIdx) : [],
          { ...mngr, expanded: false },
          ...lastIdx >= 0 ? takeRight(mngrs, count - lastIdx) : []
        ]);
      } else if (managers) {
        const managrs: ManagerItem[] = [];
        forEach(direct_reports_idx, (idx) => {
          managrs.push({ ...managers[idx], expanded: false });
        });
        setMngrs([
          ...clickedIdx > 0 ? take(mngrs, clickedIdx) : [],
          { ...mngr, expanded: true },
          ...managrs,
          ...clickedIdx + 1 < count ? takeRight(mngrs, count - clickedIdx - 1) : []
        ]);
      }
    }
  }, [managers, mngrs]);

  const { topName } = head(managers) || {};

  const [directReports, coc, all] = useMemo(() => [
    delegate && topName
      ? formatMessage({ id: 'filter.coc.leader' }, { name: topName, endsWithS: endsWith(topName, 's') })
      : formatMessage({ id: 'filter.coc.direct_reports' }),
    (!allowEmpty && expandable && topName) || formatMessage({ id: 'filter.coc.label' }),
    topName || formatMessage({ id: 'header.brand' })
  ], [delegate, allowEmpty, expandable, topName, formatMessage]);

  const managerNames = useMemo(
    () => managers ? transform(managers, (result, { id, title }, index) => {
      result[toString(id)] = allowEmpty && !topName && index < 1 ? all : title;
    }, (topName ? { [managers[0]?.topId || '']: topName } : {}) as Record<string, string>) : undefined,
    [all, topName, allowEmpty, managers]
  );

  const menuProps = useMemo(() => ({
    ...placement === 'left' ? DEFAULT_MENU_LEFT : DEFAULT_MENU_RIGHT,
    PaperProps: { className: paper },
    MenuListProps: { className: list }
  }), [placement]);

  const renderValue = useCallback(
    (val: unknown): ReactNode => (expandable && !managerNames && <Skeleton variant="text" width="9rem"/>) || (
      val === '0' || val === '' || !managerNames ? (allowEmpty && coc) || all : managerNames[toString(val)]
    ), [coc, all, allowEmpty, expandable, managerNames]
  );

  const lastIndex = size(mngrs) - 1;

  return (
    <FormControl
        ref={ref}
        variant="outlined"
        size="small"
        error={error}
        disabled={disabled || lastIndex < 0}
        className={root}
    >
      <Select
          id="coc-select"
          value={mngrs ? value : ''}
          displayEmpty
          renderValue={renderValue}
          onChange={handleChange}
          MenuProps={menuProps}
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          startAdornment={meItem ? (
            <InputAdornment position="start">
              <IconButton
                  edge="start"
                  color="primary"
                  aria-label={formatMessage({ id: 'filter.coc.me' })}
                  disabled={open || disabled || lastIndex < 0 || value === meItem.id}
                  onClick={handleMe}
                  sx={iconBtnSx}
              >
                <HomeAccount color={open || disabled || lastIndex < 0 || value === meItem.id ? 'disabled' : 'inherit'}/>
              </IconButton>
            </InputAdornment>
          ) : undefined}
          className={(value === '0' || value === '') && (allowEmpty || !expandable || !topName) ? placeholder : undefined}
      >
        {allowEmpty ? (
          <MenuItem value="0">
            <BoxTypography pl={3} py={0.5} variant="subtitle2" color="secondary.text">
              {directReports}
            </BoxTypography>
          </MenuItem>
        ) : undefined}
        {expandable ? (
          <MenuItem
              value={(allowEmpty && managers?.[0]?.topId) || '0'}
              disabled={disableTopLevel ? true : undefined}
              sx={menuItemSx}
          >
            <ChainOfCommandItem
                id="0"
                title={all}
                level={0}
                expanded
                expandable
                isLast={lastIndex < 0}
            />
          </MenuItem>
        ) : undefined}
        {map(mngrs, ({ id, title, level, direct_reports_idx, expanded }, index) => [
          ...allowEmpty || expandable || index >= 1 ? [<Divider key={`divider-${id}`} light className={divider}/>] : [],
          <MenuItem
              key={id}
              value={allowEmpty || expandable || index >= 1 ? id : '0'}
              disabled={disableTopLevel && !expandable && index === 0 ? true : undefined}
              sx={expandable ? menuItemSx : undefined}
          >
            <ChainOfCommandItem
                id={id}
                title={expandable || index >= 1 ? title : all}
                level={level ?? 1}
                onExpand={expandable ? handleExpand : undefined}
                expanded={expandable ? expanded : index < lastIndex}
                expandable={expandable}
                expandableItem={!expandable || size(direct_reports_idx) >= 1}
                isLast={index === lastIndex}
            />
          </MenuItem>
        ])}
      </Select>
      {helperText ? (
        <FormHelperText>
          {helperText}
        </FormHelperText>
      ) : undefined}
    </FormControl>
  );
});

ChainOfCommandSelector.displayName = 'ChainOfCommandSelector';

ChainOfCommandSelector.propTypes = ChainOfCommandSelectorPropTypes;

export default memo(ChainOfCommandSelector);
