import { forwardRef, memo, useMemo, useState, useCallback, useEffect, useContext } from 'react';
import size from 'lodash/size';
import take from 'lodash/take';
import isBoolean from 'lodash/isBoolean';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
// EmPath UI Components
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import { MIN_PAGE_SIZE } from '@empathco/ui-components/src/config/params';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
import OnOffSwitch from '@empathco/ui-components/src/elements/OnOffSwitch';
// local imports
import useCustomerSettings from '../config/customer';
import { PATH_SUPERVISOR_EMPLOYEE } from '../config/paths';
import { getSettingsBoolValue } from '../helpers/context';
import { SupervisorContext } from '../context/supervisor';
import { DataContext } from '../context';
import EmployeeCard from '../v3/EmployeeCard';
import CardsGrid from '../v3/CardsGrid';

const componentProps: Partial<GetComponentProps<typeof EmployeeCard>> = {
  route: PATH_SUPERVISOR_EMPLOYEE
};

// eslint-disable-next-line complexity
const TeamView = forwardRef<HTMLDivElement, {}>((_props, ref) => {
  const { HAS_REDUCED_UI } = useCustomerSettings();
  const {
    settings: { data: settingsData, pending: pendingSettings, failed: failedSettings },
    settingsUpdate: { pending: pendingSettingsUpdate }, updateSettings
  } = useContext(DataContext);
  const {
    mgrTeam: { data: team, pending: pendingTeam, failed: failedTeam }, requireTeam
  } = useContext(SupervisorContext);

  const settingsLoaded = pendingSettings === false && failedSettings === false && Boolean(settingsData);
  const settings = settingsLoaded ? settingsData : null;
  const settingsId = 'team_view' as const;
  const settingsEmployeesOnly = HAS_REDUCED_UI ? getSettingsBoolValue(settings, `${settingsId}__employees_only`) : false;
  const settingsUnfolded = getSettingsBoolValue(settings, `${settingsId}__unfolded`);

  const [employeesOnly, setEmployeesOnly] = useState<boolean | undefined>(settingsEmployeesOnly);
  const [unfolded, setUnfolded] = useState(Boolean(settingsUnfolded));

  const handleEmployeesOnly = useCallback((value: boolean) => {
    const val = Boolean(value);
    setEmployeesOnly(val);
    if (Boolean(settingsEmployeesOnly) !== val) updateSettings?.({ [`${settingsId}__employees_only`]: val });
  }, [settingsId, settingsEmployeesOnly, updateSettings]);

  const handleUnfolding = useCallback(() => {
    const val = !unfolded;
    setUnfolded(val);
    if (Boolean(settingsUnfolded) !== val) updateSettings?.({ [`${settingsId}__unfolded`]: val });
  }, [settingsId, settingsUnfolded, unfolded, updateSettings]);

  useEffect(() => {
    if (settingsLoaded && !pendingSettingsUpdate) {
      if (HAS_REDUCED_UI) setEmployeesOnly(Boolean(getSettingsBoolValue(settings, `${settingsId}__employees_only`)));
      setUnfolded(Boolean(getSettingsBoolValue(settings, `${settingsId}__unfolded`)));
    }
  }, [settingsLoaded, pendingSettingsUpdate, settings, settingsId, HAS_REDUCED_UI]);

  useEffect(() => {
    if (isBoolean(employeesOnly)) requireTeam?.({ employees_only: employeesOnly });
  }, [employeesOnly, requireTeam]);

  const showLess = useMemo(() => team ? take(team, MIN_PAGE_SIZE) : undefined, [team]);

  const showMembers = unfolded ? team : showLess;
  const count = size(showMembers);
  const teamCount = size(team);

  const pending = !settingsLoaded || pendingTeam;
  const failed = failedTeam || failedSettings;
  const disabled = pending || failed || !team || pendingSettingsUpdate;

  return (
    <CardsGrid
        ref={ref}
        variant="shady"
        items={showMembers}
        pending={pending}
        failed={failed}
        withoutTopPadding
        blendFilters
        blendPagination
        component={EmployeeCard}
        ComponentProps={componentProps}
        filters={HAS_REDUCED_UI && settingsLoaded && !failed ? (
          <Box
              flexGrow={1}
              display="flex"
              flexWrap="wrap"
              alignItems="flex-start"
              justifyContent="flex-end"
          >
            <OnOffSwitch
                label="team.employees_only"
                value={Boolean(employeesOnly)}
                onChange={handleEmployeesOnly}
                disabled={disabled ? true : undefined}
            />
          </Box>
        ) : undefined}
        pagination={!pending && !failed && count >= 1 ? (
          <Box flexGrow={1} display="flex" justifyContent="flex-end">
            <FormattedMessage
                id={teamCount > MIN_PAGE_SIZE ? 'team.fold' : 'team.showing_all'}
                values={{
                  showing: count,
                  all: teamCount,
                  link: (
                    <StandardLink
                        text={unfolded ? 'common.show_less' : 'common.show_more'}
                        onClick={disabled ? undefined : handleUnfolding}
                    />
                  )
                }}
            />
          </Box>
        ) : undefined}
    />
  );
});

TeamView.displayName = 'TeamView';

export default memo(TeamView);
