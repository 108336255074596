import { memo, useCallback, useLayoutEffect, useMemo, useState, type FunctionComponent } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import without from 'lodash/without';
import orderBy from 'lodash/orderBy';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedMessage } from 'react-intl';
import { useReactiveVar } from '@apollo/client';
// Material UI imports
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import TableSortLabel from '@mui/material/TableSortLabel';
// EmPath UI Components
import { type ExportFormat } from '@empathco/ui-components/src/models/exportFormat';
import SortArrow from '@empathco/ui-components/src/icons/SortArrow';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import TagLabel from '@empathco/ui-components/src/elements/TagLabel';
import MatchIndicator from '@empathco/ui-components/src/elements/MatchIndicator';
import ExportButton from '@empathco/ui-components/src/elements/ExportButton';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import DataTable from '@empathco/ui-components/src/elements/DataTable';
// local imports
import { SortDirection } from '../graphql/types';
import { DevPlanProgress, DevPlanTarget, TalentEmployeeObject, TalentManager } from '../graphql/customTypes';
import {
  DEFAULT_DPE_SORT_DIRECTION, DevPlanEmployeesSort, DPE_SORT_ORDERS, VALID_SORT_DIRECTION
} from '../constants/talentFinder';
import useCustomerSettings from '../config/customer';
import { devPlanSortVar } from '../context/variables';
import useExport from '../hooks/useExport';
import CohortAvatar from '../elements/CohortAvatar';
import EmployeeName from '../elements/EmployeeName';
import SortSelector from '../elements/SortSelector';
// SCSS imports
import { sortSelector } from './DevPlanEmployeesTable.module.scss';

type DevPlanEmployeesTableProps = {
  target: DevPlanTarget | null;
  hasActivities: boolean;
  employees?: DevPlanProgress[];
  cohortTitle?: string | null;
  onClick?: (employee: DevPlanProgress) => void;
  disabled?: boolean | null;
  pending?: boolean | null;
  failed?: boolean | null;
  onExport?: (format: ExportFormat, title: string) => Promise<number | boolean> | null;
  exportDisabled?: boolean;
};

const DevPlanEmployeesTablePropTypes = {
  // attributes
  target: PropTypes.string as Validator<DevPlanTarget>,
  hasActivities: PropTypes.bool.isRequired,
  employees: PropTypes.array as Validator<DevPlanProgress[]>,
  cohortTitle: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  onExport: PropTypes.func,
  exportDisabled: PropTypes.bool
};

// eslint-disable-next-line complexity
const DevPlanEmployeesTable: FunctionComponent<DevPlanEmployeesTableProps> = ({
  target,
  hasActivities,
  employees,
  cohortTitle,
  onClick,
  disabled = false,
  pending = false,
  failed = false,
  onExport,
  exportDisabled = false
}) => {
  const { HAS_COURSES, HAS_MENTORING, HAS_DEV_PLAN_OPPORTUNITIES } = useCustomerSettings();

  const sortSettings = useReactiveVar(devPlanSortVar);

  const [sortOrder, setSortOrder] = useState<DevPlanEmployeesSort>(
    DEFAULT_DPE_SORT_DIRECTION[sortSettings.sortOrder]
      ? sortSettings.sortOrder : DevPlanEmployeesSort.match_rate
  );
  const [direction, setDirection] = useState<SortDirection>(
    VALID_SORT_DIRECTION[sortSettings.direction]
      ? sortSettings.direction
      : DEFAULT_DPE_SORT_DIRECTION[sortSettings.sortOrder] || DEFAULT_DPE_SORT_DIRECTION[DevPlanEmployeesSort.match_rate]
  );
  useLayoutEffect(() => {
    setSortOrder(DEFAULT_DPE_SORT_DIRECTION[sortSettings.sortOrder] ? sortSettings.sortOrder : DevPlanEmployeesSort.match_rate);
    setDirection(VALID_SORT_DIRECTION[sortSettings.direction] ? sortSettings.direction
      : DEFAULT_DPE_SORT_DIRECTION[sortSettings.sortOrder] || DEFAULT_DPE_SORT_DIRECTION[DevPlanEmployeesSort.match_rate]
    );
  }, [sortSettings.direction, sortSettings.sortOrder]);

  const [sortByName, sortByJob, sortByStatus, sortByMatchRate, sortByCourses, sortByAdvisors, sortByOpps, sortByActivities] =
  useMemo(() => map(DPE_SORT_ORDERS, (sorting: DevPlanEmployeesSort) => DEFAULT_DPE_SORT_DIRECTION[sorting]
    ? () => devPlanSortVar({
        sortOrder: sorting,
        direction: sortOrder === sorting
          ? (direction === SortDirection.ascending && SortDirection.descending) || SortDirection.ascending
          : DEFAULT_DPE_SORT_DIRECTION[sorting] as SortDirection
      })
    : undefined
  ), [direction, sortOrder]);

  const titleValues = useMemo(() => ({ title: cohortTitle }), [cohortTitle]);

  const employeesCount = size(employees);
  const actionsDisabled = disabled || !employees || employeesCount < 1;

  const titles = useMemo(() => map([
    { label: 'hr.dev_plan.column.employee', values: undefined, id: DevPlanEmployeesSort.employee, handler: sortByName },
    { label: 'hr.dev_plan.column.current_job', values: undefined, id: DevPlanEmployeesSort.current_job, handler: sortByJob },
    { label: 'hr.dev_plan.column.status', values: undefined, id: DevPlanEmployeesSort.status, handler: sortByStatus },
    {
      label: 'hr.dev_plan.column.match_rate', values: { target }, id: DevPlanEmployeesSort.match_rate, handler: sortByMatchRate
    },
    ...HAS_COURSES ? [{
      label: 'hr.dev_plan.column.courses', values: undefined, id: DevPlanEmployeesSort.courses, handler: sortByCourses
    }] : [],
    ...HAS_MENTORING ? [{
      label: 'hr.dev_plan.column.advisors', values: undefined, id: DevPlanEmployeesSort.advisors, handler: sortByAdvisors
    }] : [],
    ...HAS_DEV_PLAN_OPPORTUNITIES ? [{
      label: 'hr.dev_plan.column.opportunities', values: undefined, id: DevPlanEmployeesSort.opportunities, handler: sortByOpps
    }] : [],
    ...hasActivities ? [{
      label: 'hr.dev_plan.column.activities', values: undefined, id: DevPlanEmployeesSort.activities, handler: sortByActivities
    }] : []
  ], ({ id, label, values, handler }) => id && handler ? (
    <TableSortLabel key={label}
        direction={(sortOrder === id && direction ? direction : DEFAULT_DPE_SORT_DIRECTION[id]) === SortDirection.ascending
          ? 'asc' : 'desc'}
        active={sortOrder === id}
        onClick={handler}
        disabled={actionsDisabled}
        IconComponent={SortArrow}
    >
      <FormattedMessage id={label} values={values}/>
    </TableSortLabel>
  ) : <FormattedMessage key={label} id={label}/>
  ), [
    actionsDisabled, sortOrder, direction, target, hasActivities,
    sortByName, sortByJob, sortByStatus, sortByMatchRate, sortByCourses, sortByAdvisors, sortByOpps, sortByActivities,
    HAS_COURSES, HAS_DEV_PLAN_OPPORTUNITIES, HAS_MENTORING
  ]);

  const rows = useMemo(() => {
    const sorted = orderBy(employees, ({
      first_name, last_name, current_match_rate, current_job, is_devplan_active,
      completed_courses, completed_advisors, completed_opportunities, activities
    }) => {
      if (sortOrder === DevPlanEmployeesSort.employee) return `${last_name}~${first_name}`;
      if (sortOrder === DevPlanEmployeesSort.current_job) return `${current_job?.title}~${current_job?.code}`;
      if (sortOrder === DevPlanEmployeesSort.match_rate) return current_match_rate || 0;
      if (sortOrder === DevPlanEmployeesSort.status) return (is_devplan_active ? 10000 : 0) + (current_match_rate || 0);
      if (sortOrder === DevPlanEmployeesSort.courses) return 10000 * size(completed_courses) + (current_match_rate || 0);
      if (sortOrder === DevPlanEmployeesSort.advisors) return 10000 * size(completed_advisors) + (current_match_rate || 0);
      if (sortOrder === DevPlanEmployeesSort.opportunities) {
        return 10000 * size(completed_opportunities) + (current_match_rate || 0);
      }
      if (sortOrder === DevPlanEmployeesSort.activities) return 10000 * size(activities) + (current_match_rate || 0);
      return 0;
    }, [direction === SortDirection.descending ? 'desc' : 'asc']);

    return map(sorted, (employee) => {
      const {
        current_match_rate, is_devplan_active, current_job,
        completed_courses, completed_advisors, completed_opportunities, activities
      } = employee || {};
      return {
        selected: false,
        values: [
          /* eslint-disable react/jsx-key */
          <EmployeeName
              employee={employee}
              onClick={onClick as (((employee: TalentEmployeeObject) => void) | undefined)}
              disabled={disabled || pending ? true : undefined}
          />,
          current_job?.title,
          <TagLabel variant={is_devplan_active ? 'active' : 'inactive'} small/>,
          <MatchIndicator
              value={toSafeInteger(current_match_rate)}
              variant="planned"
          />,
          ...HAS_COURSES ? [
            size(completed_courses) < 1 ? '—' : (
              <>
                {map(completed_courses, ({ id, title }) => (
                  <Box key={id}>
                    {title}
                  </Box>
                ))}
              </>
            )
          ] : [],
          ...HAS_MENTORING ? [
            size(completed_advisors) < 1 ? '—' : (
              <>
                {map(completed_advisors, (advisor) => (
                  <EmployeeName
                      key={advisor.id}
                      employee={advisor as unknown as TalentManager}
                      disabled={disabled || pending ? true : undefined}
                      supervisor
                  />
                ))}
              </>
            )
          ] : [],
          ...HAS_DEV_PLAN_OPPORTUNITIES ? [
            size(completed_opportunities) < 1 ? '—' : (
              <>
                {map(completed_opportunities, ({ id, title }) => (
                  // TODO: link to opportunity screen (but does current user have access?)
                  <Box key={id}>
                    {title}
                  </Box>
                ))}
              </>
            )
          ] : [],
          ...hasActivities ? [
            size(activities) < 1 ? '—' : (
              <>
                {map(activities, ({ id, activity }) => (
                  // TODO: open activity popup on click?
                  <Box key={id}>
                    {activity.name}
                  </Box>
                ))}
              </>
            )
          ] : []
          /* eslint-enable react/jsx-key */
        ]
      };
    });
  }, [
    employees, direction, sortOrder, disabled, pending, onClick, hasActivities,
    HAS_COURSES, HAS_DEV_PLAN_OPPORTUNITIES, HAS_MENTORING
  ]);

  const handleSort = useCallback((sortValue: DevPlanEmployeesSort, _newDir?: boolean | null) => devPlanSortVar({
    sortOrder: sortValue,
    direction: DEFAULT_DPE_SORT_DIRECTION[sortValue]
  }), []);

  const getExport = useCallback((format: ExportFormat, _token: string) => {
    if (exportDisabled || !cohortTitle || !onExport) return null;
    return onExport(format, cohortTitle);
  }, [exportDisabled, cohortTitle, onExport]);

  const exp = useExport(getExport);

  return (
    <>
      <CardTitle
          compact={!pending && !failed}
          title={cohortTitle ? 'hr.dev_plan.cohort_progress' : <Skeleton variant="text" width="12rem"/>}
          values={titleValues}
          avatar={<CohortAvatar grey/>}
          action={(
            <>
              <SortSelector
                  variant="employees"
                  value={sortOrder}
                  onChange={handleSort}
                  disabled={disabled ? true : undefined}
                  values={HAS_MENTORING && HAS_COURSES && HAS_DEV_PLAN_OPPORTUNITIES && hasActivities ? DPE_SORT_ORDERS
                    : without(DPE_SORT_ORDERS, ...[
                        ...HAS_COURSES ? [] : [DevPlanEmployeesSort.courses],
                        ...HAS_MENTORING ? [] : [DevPlanEmployeesSort.advisors],
                        ...HAS_DEV_PLAN_OPPORTUNITIES ? [] : [DevPlanEmployeesSort.opportunities],
                        ...hasActivities ? [] : [DevPlanEmployeesSort.activities]
                      ])}
                  className={onExport ? sortSelector : undefined}
              />
              {onExport ? (
                <ExportButton
                    pending={exp.failed === false}
                    disabled={disabled || !cohortTitle || exportDisabled || exp.failed === false || !exp.enabled}
                    onExport={exp.handleExport}
                />
              ) : undefined}
            </>
          )}
      />
      {(failed && <FetchFailedAlert flat/>) || (pending && <LoadingPlaceholder flat/>) || (
        <CardSection>
          <DataTable
              titles={titles}
              empty="hr.talentfinder.empty"
              data={rows}
              pending={disabled || !employees}
              lastLeftAlignedTitle={1}
          />
          <BoxTypography pt={1} px={0.25} variant="body1" color="info.caption">
            <FormattedMessage id="hr.cohorts.employees_count" values={{ count: employeesCount }}/>
          </BoxTypography>
        </CardSection>
      )}
    </>
  );
};

DevPlanEmployeesTable.propTypes = DevPlanEmployeesTablePropTypes;

export default memo(DevPlanEmployeesTable);
