import { memo, useEffect, useMemo, type FunctionComponent } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
// EmPath UI Components
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import useQueryCounted from '@empathco/ui-components/src/hooks/useQueryCounted';
// local imports
import { ADMIN_REPORTS_QUERY } from '../graphql/AdminReports';
import { AdminReport, AdminReportsDocument } from '../graphql/types';
import ReportCard from '../elements/ReportCard';
import CardsGrid from '../v3/CardsGrid';

type AdminReportsProps = {
  // for Storybook only
  testPending?: boolean;
}

const AdminReportsPropTypes = {
  testPending: PropTypes.bool
};

const AdminReports: FunctionComponent<AdminReportsProps> = ({
  testPending
}) => {

  // lazy load reports
  const { query: getReports, pending: pendingReports, failed, results: reports } = useQueryCounted({
    data: undefined as unknown as AdminReport,
    key: 'adminReports',
    lazyQuery: useLazyQuery(ADMIN_REPORTS_QUERY as typeof AdminReportsDocument)
  });
  const pending = pendingReports || testPending;

  useEffect(() => {
    getReports?.();
  }, [getReports]);

  const componentProps: Partial<GetComponentProps<typeof ReportCard>> = useMemo(() => ({}), []);
  
  return (
    <CardsGrid
        items={reports}
        variant="white"
        withReloading
        notFoundMessage="admin.reports.empty"
        withoutTopPadding
        blendFilters
        disabled={pending}
        pending={pending}
        failed={failed}
        component={ReportCard}
        ComponentProps={componentProps}
    />
  );
};

AdminReports.propTypes = AdminReportsPropTypes;

export default memo(AdminReports);
