import { memo, type MouseEventHandler, type ReactNode, type FunctionComponent, useMemo } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import join from 'lodash/join';
import isArray from 'lodash/isArray';
import toString from 'lodash/toString';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
// EmPath UI Components
import { mapChunks } from '@empathco/ui-components/src/helpers/intl';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import useCustomerSettings from '../config/customer';
// Media and SCSS imports
import { title, intro, button, buttonPending, privacyLink, textLink } from './SplashText.module.scss';

type SplashVariant = 'welcome' | 'access_denied' | 'logging_in' | 'login' | 'multiple_sso' | 'set_password';

type SplashTextProps = {
  children?: ReactNode | ReactNode[];
  variant: SplashVariant;
  name?: string;
  privacy?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  pending?: boolean | null;
  disabled?: boolean | null;
};

const SplashTextPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  // attributes
  variant: PropTypes.string.isRequired as Validator<SplashVariant>,
  name: PropTypes.string,
  privacy: PropTypes.bool,
  onClick: PropTypes.func,
  pending: PropTypes.bool,
  disabled: PropTypes.bool
};

const SplashText: FunctionComponent<SplashTextProps> = ({
  children,
  variant,
  name,
  privacy = false,
  onClick,
  pending = false,
  disabled = false
}) => {
  const { PRIVACY_NOTICE_URL, PROFILE_BUILDER_VIDEO_URL } = useCustomerSettings();

  const values = useMemo(() => ({
    br: <br/>,
    name,
    company: <FormattedMessage id="header.company"/>,
    video: Boolean(variant === 'welcome' && PROFILE_BUILDER_VIDEO_URL),
    // eslint-disable-next-line react/no-unstable-nested-components
    link: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
      <StandardLink href={PROFILE_BUILDER_VIDEO_URL} className={textLink}>
        {mapChunks(chunks)}
      </StandardLink>
    ),
    // eslint-disable-next-line react/no-unstable-nested-components
    mailto: (chunks?: ReactNode | ReactNode[] | null): ReactNode => (
      <StandardLink href={`mailto:${isArray(chunks) ? join(map(chunks, toString)) : toString(chunks)}`} className={textLink}>
        {mapChunks(chunks)}
      </StandardLink>
    )
  }), [name, variant, PROFILE_BUILDER_VIDEO_URL]);

  return (
    <>
      <BoxTypography pt={14} px={6} variant="h2" align="center" className={title}>
        <FormattedMessage
            id={`splash.${variant}.hello`}
            values={values}
        />
      </BoxTypography>
      <BoxTypography pt={9} px={6} variant="subtitle1" align="center" className={intro}>
        <FormattedMessage
            id={`splash.${variant}.intro`}
            values={values}
        />
      </BoxTypography>
      {children}
      {onClick ? (
        <Box pt={11} pb={22} px={6} display="flex" justifyContent="center">
          <Button
              color={pending || disabled ? 'info' : 'primary'}
              variant="contained"
              size="large"
              disableElevation
              onClick={onClick}
              disabled={pending || disabled ? true : undefined}
              startIcon={pending ? <CircularProgress size={18} color="inherit"/> : undefined}
              className={pending ? buttonPending : button}
          >
            <FormattedMessage id={`splash.${variant}.button`}/>
          </Button>
        </Box>
      ) : undefined}
      {PRIVACY_NOTICE_URL && privacy ? (
        <Box pb={6} px={6} textAlign="center">
          <StandardLink
              variant="subtitle1"
              active
              text="welcome.data_privacy.text"
              href={PRIVACY_NOTICE_URL}
              className={privacyLink}
          />
        </Box>
      ) : undefined}
    </>
  );
};

SplashText.propTypes = SplashTextPropTypes;

export default memo(SplashText);
