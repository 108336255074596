/* eslint-disable max-lines */
import {
  memo, useCallback, useEffect, useState, useMemo, useLayoutEffect, type FunctionComponent, type MouseEvent
} from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import omit from 'lodash/omit';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import reject from 'lodash/reject';
import filter from 'lodash/filter';
import values from 'lodash/values';
import transform from 'lodash/transform';
import findIndex from 'lodash/findIndex';
import mapValues from 'lodash/mapValues';
import constant from 'lodash/constant';
import max from 'lodash/max';
import isEqual from 'lodash/isEqual';
import zod from 'zod';
import { useForm, Controller, type SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
// EmPath UI Components
import type { GetComponentProps } from '@empathco/ui-components/src/helpers/types';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import CloseIconButton from '@empathco/ui-components/src/elements/CloseIconButton';
import PlusButton from '@empathco/ui-components/src/elements/PlusButton';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import CardTitle from '@empathco/ui-components/src/elements/CardTitle';
import CardFooter from '@empathco/ui-components/src/elements/CardFooter';
import ScopeSelector from '@empathco/ui-components/src/elements/ScopeSelector';
// local imports
import { ActivitySkill, CustomActivityInput, DevPlanTargetSkill, SkillActivity, SkillActivityType } from '../graphql/types';
import { SKILL_ACTIVITY_TYPES } from '../graphql/customTypes';
import { ILookupSkill } from '../models/lookupItem';
import { SKILL_LEVEL_FIRST, SKILL_LEVEL_MAX, SKILL_LEVEL_REGULAR, Skill, SkillLevel } from '../models/skill';
import { SkillGroup } from '../context/persistent';
import SkillChip from '../elements/SkillChip';
import AddSkillsDialog from './AddSkillsDialog';
import SkillLevelDialog from './SkillLevelDialog';

const typeLabelSx = { pb: 3.5 };
const skillContainerSx = { display: 'flex', alignItems: 'center', flexWrap: 'wrap' };
const resetBtnSx = { ml: 'auto', mr: 0.75, alignSelf: 'center', py: 1.5, px: 2.5 };

const sortActivitySkills = (skills: Skill[]) => sortBy(skills, ['title', 'id']);

type CustomActivityEditorProps = {
  activity?: SkillActivity;
  targetSkills?: DevPlanTargetSkill[];
  onClose: (activityId?: number, activityInput?: CustomActivityInput, skills?: ActivitySkill[]) => void;
  pending?: boolean;
}

const CustomActivityEditorPropTypes = {
  activity: PropTypes.object as Validator<SkillActivity>,
  targetSkills: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  pending: PropTypes.bool
};

// eslint-disable-next-line max-statements, max-lines-per-function
const CustomActivityEditor: FunctionComponent<CustomActivityEditorProps> = ({
  activity,
  targetSkills: parentTargetSkills,
  onClose,
  pending = false
}) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const activityId = activity?.id;

  // this dialog
  const open = Boolean(activity);
  const [mounted, setMounted] = useState(open);
  useEffect(() => {
    if (open) setMounted(true);
  }, [open]);
  const transitionProps = useMemo(() => ({ onExited: () => {
    setMounted(false);
  } }), []);
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  // activity type
  const savedActivityType = activity?.activity_type;

  const [activityType, setActivityType] = useState<string | null>(savedActivityType || null);

  useLayoutEffect(() => {
    setActivityType(savedActivityType || null);
  }, [savedActivityType]);

  const handleActivityType = useCallback((value: string) => {
    setActivityType((prevActivityType) => value === prevActivityType ? null : value);
  }, []);

  // Activity Skills
  const [activitySkills, activitySelectedSkills] = useMemo(() => {
    if (!activity?.skills || size(activity.skills) < 1) return [[] as Skill[], {}];
    const skls = transform(activity.skills, (result, skl) => {
      const { id, skill_proficiency_level } = skl;
      const skill = result[id];
      if (skill_proficiency_level && (skill?.current_level || 0) < skill_proficiency_level) {
        result[id] = {
          id: skl.id,
          abbr: skl.abbr,
          title: skl.title,
          current_level: skill_proficiency_level as SkillLevel
        };
      }
    }, {} as Record<number, Skill>)
    return [values(skls), mapValues(skls, constant(true))];
  }, [activity]);
  const targetSkills = useMemo(() => map(parentTargetSkills, ({ id, abbr, title, skill_proficiency_level }) => ({
    id,
    abbr,
    title,
    current_level: skill_proficiency_level as SkillLevel || SKILL_LEVEL_REGULAR,
  } as Skill)), [parentTargetSkills]);
  const allSkills = useMemo(() => sortActivitySkills(uniqBy([
    ...activitySkills,
    ...targetSkills
  ], 'id')), [activitySkills, targetSkills]);

  const [skills, setSkills] = useState(allSkills);
  const [selectedSkills, setSelectedSkills] = useState(activitySelectedSkills);
  useEffect(() => {
    setSkills(allSkills);
  }, [allSkills]);
  useEffect(() => {
    setSelectedSkills(activitySelectedSkills);
  }, [activitySelectedSkills]);

  const skillsChanged = useMemo(() =>
    !isEqual(skills, allSkills) || !isEqual(selectedSkills, activitySelectedSkills),
    [selectedSkills, skills, activitySelectedSkills, allSkills]
  );

  const handleResetSkills = useCallback(() => {
    setSkills(allSkills);
    setSelectedSkills(activitySelectedSkills);
  }, [allSkills, activitySelectedSkills]);

  // add/update skill dialog
  const [anchorAddBtn, setAnchorAddBtn] = useState<HTMLButtonElement | null>(null);
  const [openAddSkill, setOpenAddSkill] = useState(false);
  const [skl, setSkl] = useState<Skill | null>(null);
  const handleUpdateSkillClose = useCallback(() => setOpenAddSkill(false), []);
  const handleUpdateSkillExited = useCallback(() => setSkl(null), []);
  const handleAddSkillOpen = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (event) setAnchorAddBtn(event.currentTarget);
  }, []);
  const handleAddSkillClose = useCallback(() => setAnchorAddBtn(null), []);

  // Custom Activity Editor form
  const schema = useMemo(() => zod.object({
    name: zod.string().min(1, { message: formatMessage({ id: 'hr.custom_activities.input.name.required' }) }),
    description: zod.string().min(1, { message: formatMessage({ id: 'hr.custom_activities.input.description.required' }) }),
    activity_type: zod.string().optional(),
    skills: zod.array(zod.object({
      id: zod.number().int()
        .min(1),
      level: zod.number().int()
        .min(SKILL_LEVEL_FIRST)
        .max(SKILL_LEVEL_MAX),
      abbr: zod.string(),
      title: zod.string()
    })).optional()
  }), [formatMessage]);

  type Schema = zod.infer<typeof schema>;

  const defaultSkills = useMemo(() => transform(skills, (results, { id, abbr, title, current_level }) => {
    results.push({ id, abbr, title, level: current_level as number });
  }, [] as NonNullable<Schema['skills']>), [skills]);

  const formParams = useMemo(() => ({
    resolver: zodResolver(schema),
    defaultValues: {
      name: activity?.name || '',
      description: activity?.description || '',
      activity_type: activity?.activity_type || '',
      skills: defaultSkills
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } as Parameters<typeof useForm<Schema>>[0]), [
    // ignoring `defaultSkills` changes:
    schema, activity
  ]);

  const { control, setValue, reset, handleSubmit } = useForm<Schema>(formParams);

  useLayoutEffect(() => {
    reset(formParams?.defaultValues as Schema);
  }, [formParams, reset]);

  useLayoutEffect(() => {
    setValue('activity_type', activityType || undefined);
  }, [activityType, setValue]);

  useLayoutEffect(() => {
    setValue('skills', defaultSkills);
  }, [defaultSkills, setValue]);

  const onSubmit: SubmitHandler<Schema> = useCallback((formData: Schema) => {
    const updatedSkills = filter(formData.skills, ({ id }) => selectedSkills[id]);
    onClose(
      activityId,
      {
        ...omit(formData, ['activity_type', 'skills']),
        activity_type: !formData.activity_type || formData.activity_type === '' ? null
          : formData.activity_type as SkillActivityType,
        skill_ids: map(updatedSkills || [], 'id'),
        levels: map(updatedSkills || [], 'level')
      },
      map(updatedSkills || [], ({ id, level, abbr, title }) => ({
        id, skill_proficiency_level: level, abbr, title
      }))
    );
  }, [activityId, selectedSkills, onClose]);

  const [nameLabel, descriptionLabel] = useMemo(() => [
    formatMessage({ id: 'hr.custom_activities.input.name' }),
    formatMessage({ id: 'hr.custom_activities.input.description' })
  ], [formatMessage]);

  const renderNameField = useCallback(({
    field, fieldState: { invalid, error }
  }: Parameters<GetComponentProps<typeof Controller<Schema, 'name'>>['render']>[0]) => (
    <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        variant="outlined"
        margin="dense"
        size="small"
        fullWidth
        label={nameLabel}
        error={invalid}
        helperText={error?.message || '\u00A0'}
        disabled={pending}
    />
  ), [pending, nameLabel]);

  const renderDescriptionField = useCallback(({
    field, fieldState: { invalid, error }
  }: Parameters<GetComponentProps<typeof Controller<Schema, 'description'>>['render']>[0]) => (
    <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...field}
        variant="outlined"
        margin="dense"
        size="small"
        fullWidth
        multiline
        rows={4}
        label={descriptionLabel}
        error={invalid}
        helperText={error?.message || '\u00A0'}
        disabled={pending}
    />
  ), [pending, descriptionLabel]);

  const renderTypeField = useCallback(({
    field
  }: Parameters<GetComponentProps<typeof Controller<Schema, 'activity_type'>>['render']>[0]) => (
    <ScopeSelector
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...omit(field, ['value', 'onChange'])}
        simple
        scope={SKILL_ACTIVITY_TYPES}
        disabled={pending}
        value={activityType || ''}
        onChange={handleActivityType}
    />
  ), [activityType, pending, handleActivityType]);

  const handleAddSkill = useCallback((skill?: Skill | ILookupSkill | null) => {
    setAnchorAddBtn(null);
    if (skill?.id) {
      setOpenAddSkill(true);
      setSkl({
        ...skill,
        inferred_level: SKILL_LEVEL_FIRST,
        is_inference_newer: true,
        current_level: null,
        target_level: null,
        actual_level: null,
        expected_level: null,
        skill_proficiency_level: null,
        level: null
      } as Skill);
    }
  }, []);

  const handleAddSkillGroup = useCallback((sklGrp?: SkillGroup | null) => {
    if (sklGrp?.skills && size(sklGrp?.skills) >= 1) {
      // add skills
      setSkills((prevSkills) => sortActivitySkills(transform(sklGrp?.skills, (results, sk) => {
        const sklIdx = findIndex(prevSkills, ['id', sk.id]);
        if (sklIdx >= 0) {
          results[sklIdx] = {
            ...results[sklIdx],
            current_level: max([sk.expected_level || sk.current_level, results[sklIdx].current_level, SKILL_LEVEL_FIRST])
          };
        } else {
          results.push({
            ...sk,
            current_level: max([sk.expected_level || sk.current_level, SKILL_LEVEL_FIRST])
          });
        }
      }, prevSkills)));
      // select added skills
      setSelectedSkills((prevSelectedSkills) => transform(sklGrp?.skills, (result, { id }) => {
        result[id] = true;
      }, { ...prevSelectedSkills }));
    }
    setAnchorAddBtn(null);
  }, []);

  const handleUpdateSkill = useCallback((level: SkillLevel | null) => {
    if (skl && level) {
      setSkills((prevSkills) => findIndex(prevSkills, ['id', skl.id]) >= 0
        ? map(prevSkills, (sk) => sk.id === skl.id ? { ...sk, current_level: level } : sk)
        : sortActivitySkills(uniqBy([{ ...skl, current_level: level }, ...prevSkills], 'id'))
      );
      setSelectedSkills((prevSelectedSkills) => ({
        ...prevSelectedSkills,
        [skl.id]: true
      }));
      setOpenAddSkill(false);
    }
  }, [skl]);

  const handleDeleteSkill = useCallback((id: number, level?: SkillLevel) => {
    if (id && level) {
      setSkills((prevSkills) => reject(prevSkills, { id, current_level: level }));
      setSelectedSkills((prevSelectedSkills) => omit(prevSelectedSkills, id));
    }
  }, []);

  const handleSelectSkill = useCallback((id: number, _abbr: string, level?: SkillLevel | null) => {
    if (level) setSelectedSkills((prevSelectedSkills) =>
      prevSelectedSkills[id] ? omit(prevSelectedSkills, id) : {
        ...prevSelectedSkills,
        [id]: true
      }
    );
  }, []);

  const handleUpdateSkillOpen = useCallback((id: number, _abbr: string) => {
    const sk = find(skills, ['id', id]);
    if (sk) {
      setOpenAddSkill(true);
      setSkl({
        ...sk,
        inferred_level: sk.current_level || SKILL_LEVEL_FIRST,
        is_inference_newer: true,
        current_level: null,
        target_level: null,
        actual_level: null,
        expected_level: null,
        skill_proficiency_level: null,
        level: null
      });
    }
  }, [skills]);

  const excludeSkillIds = useMemo(() => map(skills, 'id'), [skills]);

  const renderSkillsField = useCallback(({
    fieldState: { invalid }
  }: Parameters<GetComponentProps<typeof Controller<Schema, 'skills'>>['render']>[0]) => (
    <FormControl
        variant="outlined"
        size="small"
        error={invalid}
        fullWidth
    >
      <Box sx={skillContainerSx}>
        <BoxTypography variant="h4" pb={1}>
          <FormattedMessage id="hr.custom_activities.input.skills"/>
        </BoxTypography>
        <Box width="100%"/>
        {map(skills, (skill) => (
          <SkillChip
              key={`${skill.id}-${skill.current_level}`}
              skill={skill}
              level={skill.current_level}
              disabled={pending}
              active={selectedSkills[skill.id]}
              onActivate={handleSelectSkill}
              onDelete={handleDeleteSkill}
              onEdit={handleUpdateSkillOpen}
          />
        ))}
        <PlusButton
            label="supervisor.add_skill"
            disabled={pending}
            onClick={handleAddSkillOpen}
        />
        <Button
            color="primary"
            variant="text"
            disabled={pending || !skillsChanged}
            onClick={handleResetSkills}
            sx={resetBtnSx}
        >
          <FormattedMessage id="hr.custom_activities.button.reset"/>
        </Button>
      </Box>
    </FormControl>
  ), [
    skillsChanged, skills, selectedSkills, pending,
    handleAddSkillOpen, handleDeleteSkill, handleSelectSkill, handleUpdateSkillOpen, handleResetSkills
  ]);

  return mounted ? (
    <>
      <Dialog
          disableEnforceFocus
          disablePortal
          maxWidth="lg"
          fullWidth
          scroll="body"
          open={open}
          onClose={pending ? undefined : handleClose}
          TransitionProps={transitionProps}
      >
        <CloseIconButton onClick={handleClose} disabled={pending}/>
        <CardTitle title={activityId ? 'hr.custom_activities.edit' : 'hr.custom_activities.new'} withDivider/>
        <CardSection>
          <Grid container>
            <Grid container item xs={12} alignItems="center">
              <Controller
                  name="name"
                  control={control}
                  render={renderNameField}
              />
            </Grid>
            <Grid container item xs={12} alignItems="center" sx={typeLabelSx}>
              <BoxTypography variant="caption" color="secondary.text" pl={1.5} pb={0.125}>
                <FormattedMessage id="hr.custom_activities.input.type"/>
              </BoxTypography>
              <Box width="100%"/>
              <Controller
                  name="activity_type"
                  control={control}
                  render={renderTypeField}
              />
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <Controller
                  name="description"
                  control={control}
                  render={renderDescriptionField}
              />
            </Grid>
            <Grid container item xs={12} alignItems="center">
              <Controller
                  name="skills"
                  control={control}
                  render={renderSkillsField}
              />
            </Grid>
          </Grid>
        </CardSection>
        <CardFooter withDivider>
          <Button
              color="primary"
              variant="outlined"
              disabled={pending}
              onClick={handleClose}
          >
            <FormattedMessage id="common.button.cancel"/>
          </Button>
          <Box width="5%"/>
          <Button
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
              startIcon={pending ? <CircularProgress size={18} color="inherit"/> : undefined}
              // TODO: detect `dirty` form state and disable Save button if there are no changes
              disabled={pending}
              onClick={handleSubmit(onSubmit)}
          >
            <FormattedMessage id={activityId ? 'hr.custom_activities.button.save' : 'hr.custom_activities.button.create'}/>
          </Button>
        </CardFooter>
      </Dialog>
      <AddSkillsDialog
          anchorEl={anchorAddBtn}
          exclude={excludeSkillIds}
          onAdd={handleAddSkill}
          onAddGroup={handleAddSkillGroup}
          onCancel={handleAddSkillClose}
          disabled={pending}
      />
      {skl ? (
        <SkillLevelDialog
            isOpen={openAddSkill}
            skill={skl}
            plain
            depersonalized
            onUpdate={handleUpdateSkill}
            onCancel={handleUpdateSkillClose}
            onExited={handleUpdateSkillExited}
            disabled={pending}
        />
      ) : undefined}
    </>
  ) : null;
};

CustomActivityEditor.propTypes = CustomActivityEditorPropTypes;

export default memo(CustomActivityEditor);
