import { Fragment, memo, useCallback, useContext, useState, type FunctionComponent, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import pick from 'lodash/pick';
import map from 'lodash/map';
import size from 'lodash/size';
import findIndex from 'lodash/findIndex';
import isPlainObject from 'lodash/isPlainObject';
import clsx from 'clsx';
import { useApolloClient } from '@apollo/client';
import { FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
// EmPath UI Components
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import ActionFailedAlert from '@empathco/ui-components/src/elements/ActionFailedAlert';
import CardDeck from '@empathco/ui-components/src/elements/CardDeck';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import LinearProgress from '@mui/material/LinearProgress';
// local imports
import { SkillUpdateSource, SkillUpdateSourceProp } from '../constants/mixpanel';
import { Skill, SkillLevel } from '../models/skill';
import useCustomerSettings from '../config/customer';
import { PREF_SKILL_FIELDS /* , TARGET_SKILL_FIELDS, updateCachedIsTarget */ } from '../helpers/models';
import { SkillSort } from '../constants/skillsSort';
import { DataContext } from '../context';
import { hasPagination, PaginationControlsComponent } from '../v3/PaginationControls';
import ComputeUpdatesOverlay from '../v3/ComputeUpdatesOverlay';
import SkillCard from '../v3/SkillCard';
import SkillsTable from '../v3/SkillsTable';
import SkillLevelDialog from '../widgets/SkillLevelDialog';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';
import { topPadding, bottomPadding, shadyBg, dirtyBox } from '@empathco/ui-components/src/styles/modules/ItemsGrid.module.scss';

const REMOVE_ICON_TYPES = ['inferred', 'all'] as const;
type RemoveIconType = typeof REMOVE_ICON_TYPES[number];

type SkillsGridProps = {
  dirty?: boolean | null;
  skills?: Skill[] | null;
  pending?: boolean | null;
  failed?: boolean | null;
  filters?: ReactNode | ReactNode[] | null;
  pagination?: ReactNode | ReactNode[] | null;
  onItemUpdate?: ((id: number, level: SkillLevel, mentoring: boolean, targeting: boolean) => void) | null;
  onItemRemove?: ((skill: Skill) => void) | null;
  shady?: boolean | null;
  disabled?: boolean | null;
  supervisor?: boolean; // only used in table view
  isEmployee?: boolean;
  plain?: boolean;
  small?: boolean;
  readOnly?: boolean;
  manualOnly?: boolean;
  depersonalised?: boolean;
  withLink?: boolean; // if `false`, enables skill level dialog even when `isEmployee` is `false`
  withExpectedLevel?: boolean;
  withCourses?: boolean;
  withRemoveSuggested?: boolean;
  removeIcon?: RemoveIconType;
  withReloading?: boolean;
  narrowGrid?: boolean;
  // breakAfter?: number | null;
  notFoundMessage?: string;
  table?: boolean | null;
  sortBy?: SkillSort | null;
  direction?: boolean | null;
  changeSort?: ((sort: SkillSort, direction: boolean) => void);
  sortDisabled?: boolean | null;
  source?: SkillUpdateSource;
  sourceId?: number;
  updatingSkillId?: number | null;
}

const SkillsGridPropTypes = {
  // attributes
  dirty: PropTypes.bool,
  skills: PropTypes.array,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  filters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  pagination: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  onItemUpdate: PropTypes.func,
  onItemRemove: PropTypes.func,
  shady: PropTypes.bool,
  disabled: PropTypes.bool,
  supervisor: PropTypes.bool,
  isEmployee: PropTypes.bool,
  plain: PropTypes.bool,
  small: PropTypes.bool,
  readOnly: PropTypes.bool,
  manualOnly: PropTypes.bool,
  depersonalised: PropTypes.bool,
  withLink: PropTypes.bool,
  withExpectedLevel: PropTypes.bool,
  withCourses: PropTypes.bool,
  withRemoveSuggested: PropTypes.bool,
  removeIcon: PropTypes.oneOf(REMOVE_ICON_TYPES) as Validator<RemoveIconType>,
  withReloading: PropTypes.bool,
  narrowGrid: PropTypes.bool,
  // breakAfter: PropTypes.number,
  notFoundMessage: PropTypes.string,
  table: PropTypes.bool,
  sortBy: PropTypes.string as Validator<SkillSort>,
  direction: PropTypes.bool,
  changeSort: PropTypes.func,
  sortDisabled: PropTypes.bool,
  source: SkillUpdateSourceProp as Validator<SkillUpdateSource>,
  sourceId: PropTypes.number,
  updatingSkillId: PropTypes.number
};

// eslint-disable-next-line complexity
const SkillsGrid: FunctionComponent<SkillsGridProps> = ({
  skills,
  pending = false,
  failed = false,
  dirty = false,
  disabled: parentDisabled = false,
  onItemUpdate,
  onItemRemove,
  shady = false,
  supervisor = false,
  isEmployee = false,
  plain = false,
  small = false,
  readOnly = false,
  manualOnly = false,
  depersonalised = false,
  withLink,
  withExpectedLevel = false,
  withCourses = false,
  withRemoveSuggested = false,
  removeIcon,
  withReloading = false,
  narrowGrid = false,
  // breakAfter = 0,
  notFoundMessage,
  filters,
  pagination,
  table = false,
  sortBy,
  direction,
  changeSort,
  sortDisabled = false,
  source,
  sourceId,
  updatingSkillId
}) => {
  const { cache } = useApolloClient();
  const { HAS_MENTORING } = useCustomerSettings();

  const {
    skillUpdate: { pending: updatePending, failed: updateFailed, params: updateParams }, updateSkill
    // targetSkillUpdate: { pending: targetPending, failed: targetFailed, params: targetParams }, updateTargetSkill
  } = useContext(DataContext);

  const withPagination = hasPagination(pagination as PaginationControlsComponent);
  const loading = withReloading ? pending && !skills : pending || !skills;
  const reloading = Boolean(withReloading && pending && skills);

  const [open, setOpen] = useState(false);
  const [skill, setSkill] = useState<Skill | null>(null);

  const handleClick = useCallback((skl: Skill) => {
    setOpen(true);
    setSkill(skl);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleExited = useCallback(() => {
    setSkill(null);
  }, []);

  const handleLevelChange = useCallback((level: SkillLevel, is_opt_in_mentor: boolean, is_target: boolean) => {
    if (skill) {
      if (onItemUpdate) onItemUpdate(skill.id, level, is_opt_in_mentor, is_target);
      else if (source) updateSkill?.({
        apolloCache: cache,
        skill_id: skill.id,
        level,
        ...HAS_MENTORING ? { is_opt_in_mentor } : {},
        is_target,
        source,
        source_id: sourceId,
        skill: pick(skill, PREF_SKILL_FIELDS)
      });
    }
    setOpen(false);
  }, [onItemUpdate, skill, source, sourceId, updateSkill, cache, HAS_MENTORING]);

  const handleRemove = useCallback((skl: Skill) => {
    if (skl) {
      if (onItemRemove) onItemRemove(skl);
      else if (onItemUpdate) onItemUpdate(skl.id, 0, false, false);
      else if (source) updateSkill?.({
        apolloCache: cache,
        skill_id: skl.id,
        level: 0,
        ...HAS_MENTORING ? { is_opt_in_mentor: false } : {},
        is_target: false,
        source,
        source_id: sourceId,
        skill: pick(skl, PREF_SKILL_FIELDS)
      });
    }
  }, [source, sourceId, onItemUpdate, onItemRemove, updateSkill, cache, HAS_MENTORING]);

  // const handleTargetClick = useCallback((skl: Skill) => {
  //   if (skl && skl.id) updateTargetSkill?.({
  //     skill_id: skl.id,
  //     is_target: !skl.is_target,
  //     targetSkill: pick(updateCachedIsTarget(skl, !skl.is_target), TARGET_SKILL_FIELDS)
  //   });
  // }, [updateTargetSkill]);

  const updatingId = updatingSkillId || (updatePending && updateParams ? updateParams.skill_id : null);
  // const updatingTargetId = targetPending && targetParams ? targetParams.skill_id : null;

  const disabled = parentDisabled || updatePending; // || targetPending;

  const filtersContent = filters ? (
    <CardSection top={!shady} flex>
      {filters}
    </CardSection>
  ) : undefined;

  const content = (
    <>
      {(table && (
        <SkillsTable
            isEmployee={isEmployee}
            supervisor={supervisor}
            depersonalised={depersonalised}
            skills={skills}
            pending={loading}
            failed={failed}
            sortBy={sortBy}
            direction={direction}
            changeSort={changeSort}
            disabled={disabled || sortDisabled}
            updatingLevelId={updatingId}
            // TODO: withRemoveSuggested={withRemoveSuggested}
            // TODO: onRemoveClick={removeIcon || onItemRemove ? handleRemove : undefined}
            // TODO: withRemoveIcon={removeIcon === 'all'}
            onLevelClick={withLink || (withLink !== false && !isEmployee) && !readOnly ? undefined : handleClick}
            plain={plain}
            withExpectedLevel={withExpectedLevel}
        />
      )) || (failed || loading || !skills || size(skills) < 1
        ? (
          <Box
              className={clsx({
                [topPadding]: !shady && filters && (failed || loading || !skills || !notFoundMessage),
                [bottomPadding]: withPagination,
                [shadyBg]: shady,
                [dirtyBox]: dirty
              })}
          >
            {(failed && <FetchFailedAlert flat/>) ||
            ((loading || !skills) && <LoadingPlaceholder flat/>) || (
              <>
                {!shady && filters && !notFoundMessage ? <Divider/> : undefined}
                <Alert severity="info" variant="standard">
                  <FormattedMessage id={notFoundMessage || 'skills.no_skills_found'}/>
                </Alert>
              </>
            )}
          </Box>
        ) : (
          <CardDeck shady={shady}>
            {map(skills, (skl /* , index */) => (
              <Fragment key={skl.id}>
                <SkillCard
                    isEmployee={isEmployee}
                    skill={skl}
                    plain={plain}
                    small={small}
                    depersonalised={depersonalised}
                    withRemoveSuggested={withRemoveSuggested}
                    onRemoveClick={removeIcon || onItemRemove ? handleRemove : undefined}
                    withRemoveIcon={removeIcon === 'all'}
                    withExpectedLevel={withExpectedLevel}
                    withCourses={withCourses}
                    narrowGrid={narrowGrid}
                    onLevelClick={withLink || (withLink !== false && !isEmployee && !readOnly) ? undefined : handleClick}
                    // onTargetClick={handleTargetClick}
                    disabled={disabled}
                    pendingLevel={updatingId === skl.id}
                    // pendingTarget={updatingTargetId === skl.id}
                />
                {/* breakAfter === index + 1 && (
                  <Box width="100%" height="0"/>
                ) */}
              </Fragment>
            ))}
          </CardDeck>
      ))}
      {withPagination ? (
        <CardSection bottom={!shady} flex>
          {pagination}
        </CardSection>
      ) : pagination}
    </>
  );

  return (
    <Box flexGrow={1} display="flex" flexDirection="column" position="relative">
      {filtersContent}
      {reloading ? (
        <Box
            flexGrow={1}
            display="flex"
            flexDirection="column"
            position="relative"
        >
          {content}
          <Box className={overlayDefault}>
            <LinearProgress/>
          </Box>
        </Box>
      ) : content}
      {skill ? (
        <SkillLevelDialog
            isOpen={open}
            skill={skill}
            plain={plain}
            defaultLevel={withExpectedLevel && depersonalised && manualOnly ? skill.expected_level as SkillLevel : undefined}
            readOnly={readOnly}
            manualOnly={manualOnly}
            onUpdate={readOnly ? handleClose : handleLevelChange}
            onCancel={handleClose}
            onExited={handleExited}
            disabled={disabled}
            pending={updatePending}
        />
      ) : undefined}
      <ActionFailedAlert
          message="skill.level_update_error"
          open={
              updateFailed &&
              updateParams &&
              isPlainObject(updateParams) &&
              updateParams.skill_id >= 1 &&
              findIndex(skills, ['id', updateParams.skill_id]) >= 0
            ? true : undefined
          }
      />
      {/* <ActionFailedAlert
          message="skill.target_update_error"
          open={
              targetFailed &&
              targetParams &&
              isPlainObject(targetParams) &&
              (targetParams.skill_id || 0) >= 1 &&
              findIndex(skills, ['id', targetParams.skill_id]) >= 0
            ? true : undefined
          }
      /> */}
      {dirty ? <ComputeUpdatesOverlay/> : undefined}
    </Box>
  );
};

SkillsGrid.propTypes = SkillsGridPropTypes;

export default memo(SkillsGrid);
