import { forwardRef, memo, useMemo, type ReactNode, type ReactElement } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import size from 'lodash/size';
import isBoolean from 'lodash/isBoolean';
import { useIntl, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import IconButton from '@mui/material/IconButton';
// Material Icon imports
import PushPin from '@mui/icons-material/PushPin';
import PushPinOutlined from '@mui/icons-material/PushPinOutlined';
// EmPath UI Components
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import GridBox from '@empathco/ui-components/src/mixins/GridBox';
import { getParagraph, getStandardLink } from '@empathco/ui-components/src/helpers/values';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
import ExportButton, { ExportParams } from '@empathco/ui-components/src/elements/ExportButton';
import InfoButton from '@empathco/ui-components/src/elements/InfoButton';
// local imports
import { Skill } from '../models/skill';
import { Job } from '../models/job';
import {
  EmployeeCountByDate, MDTargetedJob, MDJobsSkillsGap,
  DAAverageSkills, DACloseMatchJobs, DAHiringTrends, DAJobsMovement, DAMostEffectiveCourses,
  DAMostEffectiveAdvisor, DASkillsInsights, DAEmployeeVelocity, DACurrentRoleMatch, DAJobMovementSkills,
  DASkillSupply, DASkillsDevelopment, DASkillsGrowth, DAJobRelatedSkills, DASkillCourseMapping, DAUpskillingVelocity,
  DAOrgUsage
} from '../graphql/types';
import { DashboardExportParams } from '../context/supervisor';
import TopChartSimple from './TopChartSimple';
import TopChartStacked from './TopChartStacked';
import TopChartBars from './TopChartBars';
import TopChartTimeline from './TopChartTimeline';
import TopChartVBars from './TopChartVBars';
import TopChartRadar from './TopChartRadar';
// SCSS imports
import { overlayDefault } from '@empathco/ui-components/src/styles/modules/Overlay.module.scss';
import { card, cardTitle, header, filtersContainer, content, emptyBox, emptyMsg } from './TopChart.module.scss';

const iconBtnSx = { mx: -2 };

// Manager Dashboard only
const TOP_CHART_IN_DEMAND = 'in_demand' as const;
const TOP_CHART_SKILLS = 'skills' as const;
const TOP_CHART_JOBS = 'jobs' as const;
const TOP_CHART_EMPLOYEE_SKILLS = 'employee_skills' as const; // + HRBP Dashboard: Skills
const TOP_CHART_SKILLS_GAP = 'skills_gap' as const;
// HRBP Dashboard: Skills
const TOP_CHART_SUPPLY_DEMAND = 'supply_demand' as const;
const TOP_CHART_SKILLS_GROWTH = 'skills_growth' as const;
// Skills Development chart:
const TOP_CHART_SKILLS_DEVELOPMENT = 'skills_development' as const; // without Advisors/Courses
const TOP_CHART_SKILL_ADVISORS = 'skill_advisors' as const; // Advisors variant
const TOP_CHART_SKILL_COURSES = 'skill_courses' as const; // Courses variant
const TOP_CHART_EFFECTIVE_COURSES = 'effective_courses' as const;
const TOP_CHART_COURSE_COMPLETION = 'course_completion' as const;
const TOP_CHART_EFFECTIVE_ADVISORS = 'effective_advisors' as const;
// Skill Course Mapping chart
const TOP_CHART_SKILL_COURSE_MAPPING = 'skill_course_mapping' as const;
  const TOP_CHART_COURSE_SKILL_MAPPING = 'course_skill_mapping' as const;
// HRBP Dashboard: Jobs
const TOP_CHART_FREQ_INDEMAND = 'frequent_indemand_targeted' as const;
const TOP_CHART_FREQ_SKILL_GAP = 'frequent_skill_gap' as const;
const TOP_CHART_HIRING_TRENDS = 'hiring_trends' as const;
const TOP_CHART_JOBS_MOVEMENT = 'jobs_movement' as const;
const TOP_CHART_JOB_RELATED_SKILLS = 'job_skills' as const;
// HRBP Dashboard: Employees
const TOP_CHART_JOB_MOVEMENT_SKILLS = 'job_movement_skills' as const;
const TOP_CHART_JOBS_MATCH = 'close_match_jobs' as const;
const TOP_CHART_AVG_SKILLS = 'job_average_skills' as const;
const TOP_CHART_EMPLOYEE_VELOCITY = 'employee_velocity' as const;
const TOP_CHART_CURRENT_ROLE_MATCH = 'current_role_match' as const;
const TOP_CHART_UPSKILLING = 'upskilling_velocity' as const;
const TOP_CHART_ORG_USAGE = 'usage_by_org' as const;

const TOP_CHART_VARIANTS = [
  // Manager Dashboard
  TOP_CHART_IN_DEMAND, TOP_CHART_SKILLS, TOP_CHART_JOBS, TOP_CHART_SKILLS_GAP,
  // Manager + (HRBP Dashboard: Skills)
  TOP_CHART_EMPLOYEE_SKILLS,
  // HRBP Dashboard
  TOP_CHART_JOBS_MATCH, TOP_CHART_AVG_SKILLS, TOP_CHART_SKILLS_GROWTH, TOP_CHART_SUPPLY_DEMAND,
  TOP_CHART_SKILL_ADVISORS, TOP_CHART_SKILL_COURSES, TOP_CHART_SKILLS_DEVELOPMENT, TOP_CHART_EFFECTIVE_COURSES,
  TOP_CHART_COURSE_COMPLETION, TOP_CHART_EFFECTIVE_ADVISORS, TOP_CHART_JOBS_MOVEMENT, TOP_CHART_HIRING_TRENDS,
  TOP_CHART_FREQ_SKILL_GAP, TOP_CHART_FREQ_INDEMAND, TOP_CHART_EMPLOYEE_VELOCITY, TOP_CHART_CURRENT_ROLE_MATCH,
  TOP_CHART_UPSKILLING, TOP_CHART_SKILL_COURSE_MAPPING, TOP_CHART_COURSE_SKILL_MAPPING, TOP_CHART_JOB_MOVEMENT_SKILLS,
  TOP_CHART_JOB_RELATED_SKILLS, TOP_CHART_ORG_USAGE
] as const;
export type TopChartVariant = typeof TOP_CHART_VARIANTS[number];
export const TopChartVariantProp = PropTypes.oneOf(TOP_CHART_VARIANTS);

export type TopChartDataType = (
  Skill | Job | EmployeeCountByDate | MDTargetedJob | MDJobsSkillsGap |
  DAMostEffectiveCourses | DAMostEffectiveAdvisor | DACloseMatchJobs | DAAverageSkills | DAJobsMovement |
  DASkillSupply | DASkillsDevelopment | DASkillsGrowth | DAUpskillingVelocity |
  DAEmployeeVelocity | DACurrentRoleMatch | DASkillCourseMapping | DAJobRelatedSkills | DASkillsInsights
)[];

export type TopChartLayout = 'normal' | 'wide' | 'full';

export type TopChartProps = {
  variant: TopChartVariant;
  layout?: TopChartLayout;
  info?: string | null;
  hrbp?: boolean;
  data?: TopChartDataType | null;
  totalEmployees?: number | null;
  path?: string;
  pending?: boolean | null;
  failed?: boolean | null;
  action?: ReactNode | ReactNode[];
  filters?: ReactNode | ReactNode[];
  exportEndpoint?: string;
  exportParams?: DashboardExportParams | null;
  exportDisabled?: boolean | null;
  exportPending?: boolean | null;
  withInfoButton?: boolean | null;
  optionalParams?: {
    years?: number | null;
    upwardJobMovement?: boolean | null;
    internalHiresOnly?: boolean | null;
    withoutInDemandSkills?: boolean | null;
  } | null;
  pinned?: boolean;
  onPin?: () => void;
  pinning?: boolean;
}

const TopChartPropTypes = {
  // attributes
  variant: TopChartVariantProp.isRequired,
  layout: PropTypes.string as Validator<TopChartLayout>,
  info: PropTypes.string,
  hrbp: PropTypes.bool,
  data: PropTypes.array as Validator<TopChartDataType>,
  totalEmployees: PropTypes.number,
  path: PropTypes.string,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  filters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  exportEndpoint: PropTypes.string,
  exportParams: PropTypes.object as Validator<DashboardExportParams>,
  exportDisabled: PropTypes.bool,
  exportPending: PropTypes.bool,
  withInfoButton: PropTypes.bool,
  optionalParams: PropTypes.object,
  pinned: PropTypes.bool,
  onPin: PropTypes.func,
  pinning: PropTypes.bool
};

// eslint-disable-next-line complexity, max-lines-per-function
const TopChart = forwardRef<HTMLDivElement, TopChartProps>(({
  variant,
  layout = 'normal',
  info,
  hrbp = false,
  data,
  totalEmployees,
  path,
  pending = false,
  failed = false,
  action,
  filters,
  exportEndpoint,
  exportParams,
  exportDisabled = false,
  exportPending = false,
  withInfoButton = false,
  optionalParams,
  pinned,
  onPin,
  pinning
}, ref) => {
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage } = useIntl();

  const values = useMemo(() => ({
    link: getStandardLink({ href: formatMessage({ id: 'supervisor.dashboard.in_demand.link' }) }),
    line: getParagraph()
  }), [formatMessage]);

  const infoValues = useMemo(() => ({ indemand: !optionalParams?.withoutInDemandSkills }), [optionalParams]);

  const wide = layout !== 'normal';

  const PinIcon = isBoolean(pinned) ? (pinned && PushPin) || PushPinOutlined : undefined;

  const innerContent = (
    <>
      <CardHeader
          disableTypography
          avatar={PinIcon ? (
            <IconButton
                color="primary"
                aria-label={formatMessage({ id: pinned ? 'hr.dashboard.button.unpin' : 'hr.dashboard.button.pin' })}
                disabled={pinning || !onPin ? true : undefined}
                onClick={onPin}
                sx={iconBtnSx}
            >
              <PinIcon color="inherit"/>
            </IconButton>
          ) : undefined}
          title={
            <Box className={header}>
              <FormattedMessage
                  id={`supervisor.dashboard.${variant === TOP_CHART_SKILL_ADVISORS || variant === TOP_CHART_SKILL_COURSES
                    ? TOP_CHART_SKILLS_DEVELOPMENT : variant}`}
                  values={{
                    mode: hrbp ? 'hr' : 'manager',
                    indemand: !optionalParams?.withoutInDemandSkills
                  }}
              />
            </Box>
          }
          subheader={info && !failed && !pending && size(data) >= 1 ? (withInfoButton && (
            <InfoButton
                help={info}
                values={infoValues}
                placement="right"
                small
            />
          )) || (
            <BoxTypography
                variant="body2"
                color="text.label"
                fontStyle="italic"
                pt={0.25}
                px={2}
                display="flex"
            >
              <FormattedMessage id={info} defaultMessage={info}/>
            </BoxTypography>
          ) : undefined}
          action={(action && (
            <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                flexWrap="wrap"
                pl={1}
            >
              {exportPending ? (
                <Box pr={1} color="action.disabled">
                  <CircularProgress size={22} color="inherit"/>
                </Box>
              ) : undefined}
              {size((action as ReactElement).props?.children) >= 2 ? action : (
                <Box py={1}>
                  {action}
                </Box>
              )}
              {/* TODO: ViewStyle switch */}
              {exportEndpoint ? (
                <Box pl={1} py={1}>
                  <ExportButton
                      endpoint={exportEndpoint}
                      disabled={exportDisabled || exportPending}
                      params={exportParams as ExportParams | null}
                  />
                </Box>
              ) : undefined}
            </Box>
          )) || (exportEndpoint && (
            <Box pl={1} py={1}>
              <ExportButton
                  endpoint={exportEndpoint}
                  pending={exportPending}
                  disabled={exportDisabled}
                  params={exportParams as ExportParams | null}
              />
            </Box>
          )) || undefined}
          className={cardTitle}
      />
      {filters ? (
        <>
          <Divider/>
          <Box
              display="flex"
              flexDirection="column"
              pt={1}
              className={filtersContainer}
          >
            {filters}
          </Box>
        </>
      ) : undefined}
      {(failed && <FetchFailedAlert flat/>) ||
      ((pending && !data) && <LoadingPlaceholder flat fullHeight/>) || (
        <>
          {!filters && size(data) >= 1 ? <Divider variant="middle"/> : undefined}
          {(size(data) < 1 && (
            <Box
                flexGrow={1}
                display="flex"
                flexDirection="column"
                position="relative"
            >
              <CardContent className={filters ? `${content} ${emptyBox}` : content}>
                <Alert severity="info" variant="standard" className={emptyMsg}>
                  <FormattedMessage
                      id={`supervisor.dashboard.${variant === TOP_CHART_SKILL_ADVISORS || variant === TOP_CHART_SKILL_COURSES
                        ? TOP_CHART_SKILLS_DEVELOPMENT : variant}.empty`}
                      values={values}
                  />
                </Alert>
              </CardContent>
              {pending && data ? (
                <Box className={overlayDefault}>
                  <LinearProgress/>
                </Box>
              ) : undefined}
            </Box>
          )) || (
            <Box
                flexGrow={1}
                display="flex"
                flexDirection="column"
                position="relative"
            >
              {(variant === TOP_CHART_IN_DEMAND && (
                <TopChartStacked
                    variant="default"
                    hrbp={hrbp}
                    data={data as Skill[]}
                    totalEmployees={totalEmployees}
                    path={exportPending ? undefined : path}
                    pending={pending}
                />
              )) || (variant === TOP_CHART_SKILLS_GAP && hrbp && (
                <TopChartBars
                    data={data as (Job | MDJobsSkillsGap)[]}
                    totalEmployees={totalEmployees}
                    path={exportPending ? undefined : path}
                    pending={pending}
                />
              )) || (variant === TOP_CHART_EMPLOYEE_SKILLS && (
                <TopChartTimeline
                    variant="employees"
                    wide={wide}
                    data={data as EmployeeCountByDate[]}
                    years={optionalParams?.years}
                    pending={pending}
                />
              )) || ((
                variant === TOP_CHART_SKILLS_GROWTH ||
                variant === TOP_CHART_SUPPLY_DEMAND ||
                variant === TOP_CHART_SKILL_ADVISORS ||
                variant === TOP_CHART_SKILL_COURSES ||
                variant === TOP_CHART_SKILLS_DEVELOPMENT
              ) && (
                <TopChartStacked
                    variant={variant}
                    hrbp={hrbp}
                    data={data as (DASkillSupply | DASkillsDevelopment | DASkillsGrowth)[]}
                    totalEmployees={totalEmployees}
                    path={exportPending ? undefined : path}
                    pending={pending}
                />
              )) || (variant === TOP_CHART_JOB_MOVEMENT_SKILLS && (
                <TopChartStacked
                    variant={optionalParams?.upwardJobMovement ? 'upward_job_movement' : 'adj_job_movement'}
                    hrbp={hrbp}
                    data={data as DAJobMovementSkills[]}
                    totalEmployees={totalEmployees}
                    path={exportPending ? undefined : path}
                    pending={pending}
                />
              )) || (variant === TOP_CHART_JOBS_MATCH && (
                <TopChartTimeline
                    variant="jobs"
                    wide={wide}
                    data={data as DACloseMatchJobs[]}
                    years={optionalParams?.years}
                    pending={pending}
                />
              )) || (variant === TOP_CHART_AVG_SKILLS && (
                <TopChartTimeline
                    variant="skills"
                    wide={wide}
                    data={data as DAAverageSkills[]}
                    years={optionalParams?.years}
                    pending={pending}
                />
              )) || (variant === TOP_CHART_EMPLOYEE_VELOCITY && (
                <TopChartTimeline
                    variant="velocity"
                    wide={wide}
                    data={data as DAEmployeeVelocity[]}
                    years={optionalParams?.years}
                    pending={pending}
                />
              )) || (variant === TOP_CHART_CURRENT_ROLE_MATCH && (
                <TopChartTimeline
                    variant="match"
                    wide={wide}
                    data={data as DACurrentRoleMatch[]}
                    years={optionalParams?.years}
                    pending={pending}
                />
              )) || (variant === TOP_CHART_UPSKILLING && (
                <TopChartTimeline
                    variant="upskilling"
                    wide={wide}
                    data={data as DAUpskillingVelocity[]}
                    years={optionalParams?.years}
                    path={path}
                    pending={pending}
                />
              )) || ((variant === TOP_CHART_SKILL_COURSE_MAPPING || variant === TOP_CHART_COURSE_SKILL_MAPPING) && (
                <TopChartTimeline
                    variant={variant === TOP_CHART_SKILL_COURSE_MAPPING ? 'skill_course' : 'course_skill'}
                    wide={wide}
                    data={data as DASkillCourseMapping[]}
                    years={optionalParams?.years}
                    pending={pending}
                />
              )) || ((
                variant === TOP_CHART_JOBS_MOVEMENT ||
                variant === TOP_CHART_JOB_RELATED_SKILLS ||
                variant === TOP_CHART_HIRING_TRENDS
              ) && (
                <TopChartVBars
                    variant={variant}
                    data={data as (DAJobsMovement | DAJobRelatedSkills | DAHiringTrends)[]}
                    years={optionalParams?.years}
                    path={path}
                    pending={pending}
                />
              )) || ((
                variant === TOP_CHART_FREQ_SKILL_GAP ||
                variant === TOP_CHART_FREQ_INDEMAND ||
                variant === TOP_CHART_ORG_USAGE
              ) && (
                <TopChartRadar
                    variant={(variant === TOP_CHART_ORG_USAGE && 'orgs') ||
                      (variant === TOP_CHART_FREQ_INDEMAND && 'in_demand') ||
                      'gaps'}
                    data={data as (DASkillsInsights | DAOrgUsage)[]}
                    path={path}
                    pending={pending}
                />
              )) || (
                <TopChartSimple
                    hrbp={hrbp}
                    data={data as (Skill | Job | MDTargetedJob | DAMostEffectiveCourses | DAMostEffectiveAdvisor)[]}
                    totalEmployees={totalEmployees}
                    path={exportPending ? undefined : path}
                    variant={
                      (variant === TOP_CHART_EFFECTIVE_COURSES && 'courses') ||
                      (variant === TOP_CHART_COURSE_COMPLETION && 'completion') ||
                      (variant === TOP_CHART_EFFECTIVE_ADVISORS && 'advisors') ||
                      undefined
                    }
                    pending={pending}
                />
              )}
              {pending && data ? (
                <Box className={overlayDefault}>
                  <LinearProgress/>
                </Box>
              ) : undefined}
            </Box>
          )}
        </>
      )}
    </>
  );

  return layout === 'full' ? innerContent : (
    <GridBox
        ref={ref}
        item
        xs={12}
        lg={wide ? undefined : 6}
        container
        alignItems="stretch"
        pt={2}
    >
      <Card elevation={2} className={card}>
        {innerContent}
      </Card>
    </GridBox>
  );
});

TopChart.displayName = 'TopChart';

TopChart.propTypes = TopChartPropTypes;

export default memo(TopChart);
