import { forwardRef, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import sum from 'lodash/sum';
import max from 'lodash/max';
import size from 'lodash/size';
import join from 'lodash/join';
import round from 'lodash/round';
import reject from 'lodash/reject';
import transform from 'lodash/transform';
import isNil from 'lodash/isNil';
import toString from 'lodash/toString';
import toFinite from 'lodash/toFinite';
import toSafeInteger from 'lodash/toSafeInteger';
import { type CallbackDataParams } from 'echarts/types/src/util/types';
import type EChartsReactCore from 'echarts-for-react/lib/core';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
// Material UI imports
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';
// EmPath UI Components
import { getTruncatedTitle } from '@empathco/ui-components/src/helpers/strings';
import { type EChartsMouseEvent } from '@empathco/ui-components/src/helpers/echarts';
import { spacing } from '@empathco/ui-components/src/helpers/styles';
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import useCombinedRefs from '@empathco/ui-components/src/hooks/useCombinedRefs';
// local imports
import { DAOrgUsage } from '../graphql/types';
import { DASkillsInsightCounts } from '../graphql/customTypes';
import { SKILL_LEVEL_FIRST, SKILL_LEVEL_MAX, SKILL_LEVEL_MIN } from '../models/skill';
import { GlobalEChartsStyles } from '../config/params';
import { svgPath as Hexagon } from '../icons/Hexagon';
import Chart from '../elements/Chart';
// SCSS imports
import {
  s1L1, s1L2, s1L3, s1L4,
  s2L1, s2L2, s2L3, s2L4,
  s5L1, s5L2, s5L3, s5L4,
  s6L1, s6L2, s6L3, s6L4,
  radarSeries1, radarSeries4
} from '../styles/modules/Chart.module.scss';
import {
  chart, chartPreview, tooltipHeader, tooltipLabel, marker, tooltipValue,
  tooltipRow, tooltipFirst, tooltipBars, tooltipBarContainer, tooltipLine, tooltipBar, tooltipBarLabel
} from './TopChartRadar.module.scss';

const RADAR_VARIANT = ['gaps', 'in_demand', 'orgs'] as const;
type RadarVariant = typeof RADAR_VARIANT[number];

const requiredColorCls = {
  'gaps': [s5L1, s5L2, s5L3, s5L4],
  'in_demand': [s1L1, s1L2, s1L3, s1L4],
  'orgs': [radarSeries4]
};
const actualColorCls = {
  'gaps': [s6L1, s6L2, s6L3, s6L4],
  'in_demand': [s2L1, s2L2, s2L3, s2L4],
  'orgs': [radarSeries1]
};

type TopChartRadarProps = {
  preview?: boolean;
  variant: RadarVariant;
  data?: (DASkillsInsightCounts | DAOrgUsage)[];
  path?: string;
  pending?: boolean | null;
}

const TopChartRadarPropTypes = {
  // attributes
  preview: PropTypes.bool,
  variant: PropTypes.oneOf(RADAR_VARIANT).isRequired as Validator<RadarVariant>,
  data: PropTypes.array,
  path: PropTypes.string,
  pending: PropTypes.bool
};

// eslint-disable-next-line max-lines-per-function
const TopChartRadar = forwardRef<EChartsReactCore, TopChartRadarProps>(({
  preview = false,
  variant,
  data: parentData,
  path,
  pending = false
}, ref) => {
  const navigate = useNavigate();
  const theme = useTheme();
  // eslint-disable-next-line jest/unbound-method
  const { formatMessage, formatNumber } = useIntl();

  const innerRef = useRef<EChartsReactCore>(null);
  const chartRef = useCombinedRefs<EChartsReactCore>(ref, innerRef);

  const [activeId, setActiveId] = useState<number>();

  const data = useMemo(() => variant === 'orgs'
    ? reject(parentData as DAOrgUsage[], ({ employee_count, usage_count }) =>
        (employee_count || 0) < 1 && (usage_count || 0) < 1)
    : parentData,
    [parentData, variant]);

  const lastIndex = preview ? 6 : size(data);

  const colors = useMemo(() =>
    (variant === 'orgs' && [theme.palette.chart.radarSeries4, theme.palette.chart.radarSeries1]) ||
    (variant === 'gaps' && [theme.palette.chart.radarSeries1, theme.palette.chart.radarSeries2]) ||
    [theme.palette.chart.radarSeries3, theme.palette.chart.radarSeries4], [variant, theme]);

  const seriesNames = useMemo(() => [
    formatMessage({ id: `hr.dashboard.radar.${variant === 'orgs' ? 'total' : 'required'}` }),
    formatMessage({ id: `hr.dashboard.radar.${variant === 'orgs' ? 'active' : 'actual'}` })
  ], [variant, formatMessage]);

  const counts = useMemo(() => {
    const cnts = preview
      ? (variant === 'orgs' && [
        [[350, 0], [375, 1], [350, 2], [400, 3], [350, 4], [400, 5]],
        [[300, 0], [100, 1], [270, 2], [240, 3], [280, 4], [220, 5]]
      ]) || (variant === 'gaps' && [
          [[4, 0], [3.5, 1], [3, 2], [1.5, 3], [3, 4], [2.5, 5]],
          [[1.5, 0], [2.5, 1], [4, 2], [3.5, 3], [3.75, 4], [3.5, 5]]
        ]) || [
          [[2.5, 0], [3, 1], [1.5, 2], [3, 3], [3.5, 4], [4, 5]],
          [[3.5, 0], [3.75, 1], [3.5, 2], [4, 3], [2.5, 4], [1.5, 5]]
        ] as number[][][]
      : transform(data || [], (result, rec, index) => {
          if (variant === 'orgs') {
            const { usage_count, employee_count } = rec as DAOrgUsage;
            result[0].push([toSafeInteger(employee_count), index]);
            result[1].push([toSafeInteger(usage_count), index]);
          } else {
            const { average_expected_level, average_actual_level } = rec as DASkillsInsightCounts;
            result[0].push([toFinite(average_expected_level), index]);
            result[1].push([toFinite(average_actual_level), index]);
          }
        }, [[], []] as number[][][]);
    if (lastIndex === 1) {
      cnts[0].push([cnts[0][0][0] / 2, 1]);
      cnts[0].push([0, 2]);
      cnts[0].push([cnts[0][0][0] / 2, 3]);
      cnts[0].push([cnts[0][0][0], 4]);
      cnts[1].push([cnts[1][0][0] / 2, 1]);
      cnts[1].push([0, 2]);
      cnts[1].push([cnts[1][0][0] / 2, 3]);
      cnts[1].push([cnts[1][0][0], 4]);
    } else if (lastIndex === 2) {
      cnts[0] = [
        cnts[0][0],
        [(cnts[0][0][0] + cnts[0][1][0]) / 4, 1],
        [cnts[0][1][0], 2],
        [(cnts[0][0][0] + cnts[0][1][0]) / 4, 3],
        [cnts[0][0][0], 4]
      ];
      cnts[1] = [
        cnts[1][0],
        [(cnts[1][0][0] + cnts[1][1][0]) / 4, 1],
        [cnts[1][1][0], 2],
        [(cnts[1][0][0] + cnts[1][1][0]) / 4, 3],
        [cnts[1][0][0], 4]
      ];
    } else if (lastIndex >= 1) {
      cnts[0].push([cnts[0][0][0], lastIndex]);
      cnts[1].push([cnts[1][0][0], lastIndex]);
    }
    return cnts;
  }, [data, lastIndex, variant, preview]);

  type Indicator = { id: number; title: string; abbr: string; };
  const indicators: Indicator[] = useMemo(() => {
    if (preview) return [{}, {}, {}, {}, {}, {}] as Indicator[];
    let result = map(data, (rec) => ({
      id: rec.id,
      abbr: (rec as DASkillsInsightCounts).abbr || toString(rec.id),
      title: getTruncatedTitle(rec.title)
    }));
    if (result.length === 1) result = [result[0], {} as Indicator, {} as Indicator, {} as Indicator];
    else if (result.length === 2) result = [result[0], {} as Indicator, result[1], {} as Indicator];
    return result;
  }, [data, preview]);

  const onEvents = useMemo(() => !preview && path ? {
    click: ({ componentType, value }: EChartsMouseEvent) => {
      if (componentType === 'angleAxis') try {
        const { abbr } = indicators[toSafeInteger(value)] || {};
        if (abbr) navigate(injectParams(path, { skill_id: abbr }));
      } catch (_err) {
        // nothing to do
      }
    },
    mouseover: ({ componentType, value }: EChartsMouseEvent) => {
      if (componentType === 'angleAxis') try {
        setActiveId(indicators[toSafeInteger(value)]?.id);
      } catch (_err) {
        setActiveId(undefined);
      }
    },
    mouseout: ({ componentType }: EChartsMouseEvent) => {
      if (componentType === 'angleAxis') setActiveId(undefined);
    }
  } : undefined, [indicators, path, preview, navigate]);

  const axisFormatter = useCallback((value: number) => {
    const { id, title } = indicators[value] || {};
    return title ? `{${activeId && id === activeId ? 'active' : 'normal'}Style|${title}}` : undefined;
  }, [activeId, indicators]);

  // eslint-disable-next-line complexity
  const tooltipFormatter = useCallback((params: CallbackDataParams[]) => {
    const { dataIndex } = params?.[0] || {};
    // eslint-disable-next-line no-nested-ternary
    const effectiveIdx = lastIndex === 1 ? [0, null, null, null, 0][dataIndex]
      : lastIndex === 2 ? [0, null, 1, null, 0][dataIndex]
      : (dataIndex < lastIndex && dataIndex) || 0;
    if (isNil(effectiveIdx)) return undefined;
    if (variant === 'orgs') {
      const { title, usage_count, employee_count } = (data?.[effectiveIdx || 0] || {}) as DAOrgUsage;
      return `<div><div class="${tooltipHeader}"><span>${title}</span></div><div class="${tooltipRow}"><span class="${
          marker} ${requiredColorCls.orgs[0]}">\u00A0</span><span>${
            formatMessage({ id: 'hr.dashboard.radar.total' })
          }</span><span class="${tooltipValue}">${
            formatNumber(employee_count || 0)
        }</span></div><div class="${tooltipRow}"><span class="${
          marker} ${actualColorCls.orgs[0]}">\u00A0</span><span>${
            formatMessage({ id: 'hr.dashboard.radar.active' })
          }</span><span class="${tooltipValue}">${
            formatNumber(usage_count || 0)
        }</span></div></div>`;
    }
    const {
      title, level1, level2, level3, level4, required_level_1, required_level_2, required_level_3, required_level_4
    } = (data?.[effectiveIdx || 0] || {}) as DASkillsInsightCounts;
    if (!level1 && !level2 && !level3 && !level4) return undefined;
    const levels = [level1 || 0, level2 || 0, level3 || 0, level4 || 0];
    const required = [required_level_1 || 0, required_level_2 || 0, required_level_3 || 0, required_level_4 || 0];
    const totalCount = sum(levels);
    const maxValue = max([...levels, ...required]) || 1;
    return `<div><div class="${tooltipHeader}"><span>${title}</span><span class="${tooltipLabel}">${
      formatNumber(totalCount)
    }</span></div>${join(map(levels, (count, expectedLevel) => `<div class="${tooltipRow}"><div class="${tooltipFirst}">${
        formatMessage({ id: 'common.skill_level.text' }, { level: expectedLevel + SKILL_LEVEL_FIRST })
      }</div><div class="${tooltipBars}"><span class="${tooltipBarContainer}"><span class="${tooltipLine
      }"><span class="${tooltipBar} ${
        actualColorCls[variant][expectedLevel] || ''
      } bar-width-${
        round(80 * count / maxValue) + 1
      }"></span><span class="${tooltipBarLabel}">${
        formatNumber(count)
      }</span></span><span class="${tooltipLine}"><span class="${tooltipBar} ${
        requiredColorCls[variant][expectedLevel] || ''} bar-width-${
        round(80 * required[expectedLevel] / maxValue) + 1
      }"></span><span class="${tooltipBarLabel}">${
        formatNumber(required[expectedLevel])
      }</span></span></span></div></div>`), '')
    }</div>`;
  }, [data, lastIndex, variant, formatMessage, formatNumber]);

  // eslint-disable-next-line complexity
  useEffect(() => {
    if (!innerRef.current) return;

    const echartInstance = innerRef.current.getEchartsInstance();

    echartInstance.setOption({
      ...GlobalEChartsStyles,
      silent: Boolean(preview || pending),
      polar: preview ? {
        center: ['50%', '50%'],
        radius: '100%'
      } : {
        center: ['50%', '45%'],
        radius: '60%'
      },
      legend: preview ? null : {
        bottom: 0,
        padding: [0, 0, spacing(1.5), 0],
        icon: Hexagon,
        itemWidth: spacing(2.5),
        itemHeight: spacing(2.5),
        itemGap: spacing(6),
        itemStyle: {
          borderWidth: theme.shape.thinBorderWidth
        },
        textStyle: {
          fontSize: 14,
          fontStyle: 'italic',
          color: theme.palette.info.caption,
          padding: spacing(0.125)
        }
      },
      ...preview || pending ? {} : {
        tooltip: {
          show: true,
          confine: true,
          trigger: 'axis',
          axisPointer: { type: 'none' },
          formatter: tooltipFormatter,
          borderColor: theme.palette.misc.selectedBorder,
          borderWidth: theme.shape.borderWidth,
          backgroundColor: theme.palette.background.tooltipLight,
          extraCssText: `box-shadow: ${theme.shadows[5]}`
        }
      },
      angleAxis: {
        type: 'value',
        triggerEvent: Boolean(!preview && path),
        startAngle: (indicators.length > 2 && (
          (indicators.length % 5 === 0 && -18) || (indicators.length % 3 === 0 && -30)
        )) || -45,
        min: 0,
        max: indicators.length || 1,
        interval: 1,
        minInterval: 1,
        maxInterval: 1,
        splitNumber: indicators.length || 1,
        data: indicators,
        axisLine: {
          lineStyle: {
            color: theme.palette.chart.gridRadar,
            width: theme.shape.thinBorderWidth
          }
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: theme.palette.chart.gridRadar,
            width: theme.shape.thinBorderWidth
          }
        },
        axisTick: { show: false },
        axisLabel: preview ? null : {
          formatter: axisFormatter,
          rich: {
            normalStyle: {
              color: theme.palette.secondary.text,
              fontSize: 16,
              lineHeight: 16,
              fontWeight: theme.typography.fontWeightMedium,
              padding: [spacing(0.375), spacing(1.5), spacing(0.375), spacing(1.5)]
            },
            activeStyle: {
              color: theme.palette.secondary.text,
              fontSize: 16,
              lineHeight: 16,
              fontWeight: theme.typography.fontWeightMedium,
              borderRadius: theme.shape.borderRadius,
              padding: [spacing(0.375), spacing(1.5), spacing(0.375), spacing(1.5)],
              backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity)
            }
          }
        }
      },
      radiusAxis: {
        ...variant === 'orgs' ? {
          ...preview || lastIndex === 1 || lastIndex === 2 ? {} : {
            type: 'log',
            logBase: 10
          }
        } : {
          min: SKILL_LEVEL_MIN,
          max: SKILL_LEVEL_MAX,
          splitNumber: SKILL_LEVEL_MAX - SKILL_LEVEL_MIN,
          minInterval: 1
        },
        axisLine: {
          lineStyle: {
            color: theme.palette.chart.gridRadar,
            width: theme.shape.thinBorderWidth
          }
        },
        splitLine: {
          lineStyle: {
            color: theme.palette.chart.gridRadar,
            width: theme.shape.thinBorderWidth
          }
        },
        axisTick: { show: false },
        alignTicks: false,
        splitArea: { show: false },
        axisLabel: preview ? null : {
          show: true,
          showMinLabel: false,
          fontSize: 14,
          fontWeight: theme.typography.fontWeightRegular,
          color: theme.palette.text.label
        }
      },
      series: seriesNames.map((name, index) => ({
        coordinateSystem: 'polar',
        polarIndex: 0,
        type: 'line',
        symbol: 'none',
        ...lastIndex === 1 || lastIndex === 2 ? { smooth: true } : {},
        name,
        itemStyle: { color: colors[index] },
        lineStyle: {
          opacity: 0
        },
        areaStyle: {
          color: colors[index],
          opacity: 1 - theme.palette.action.disabledOpacity
        },
        data: counts[index]
      }))
    }, true);
    echartInstance.resize();
  }, [
    indicators, counts, lastIndex, pending, colors, seriesNames, variant,
    axisFormatter, tooltipFormatter, theme, preview, path
  ]);

  return (
    <Chart
        ref={chartRef}
        option={GlobalEChartsStyles}
        className={preview ? chartPreview : chart}
        onEvents={pending ? undefined : onEvents}
    />
  );
});

TopChartRadar.displayName = 'TopChartRadar';

TopChartRadar.propTypes = TopChartRadarPropTypes;

export default memo(TopChartRadar);
