import map from 'lodash/map';
import size from 'lodash/size';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import find from 'lodash/find';
import take from 'lodash/take';
import sortBy from 'lodash/sortBy';
import values from 'lodash/values';
import filter from 'lodash/filter';
import reject from 'lodash/reject';
import isNil from 'lodash/isNil';
import isNull from 'lodash/isNull';
import forEach from 'lodash/forEach';
import indexOf from 'lodash/indexOf';
import toLower from 'lodash/toLower';
import transform from 'lodash/transform';
import head from 'lodash/head';
import startCase from 'lodash/startCase';
import type { LazyQueryHookOptions, QueryHookOptions } from '@apollo/client';
// EmPath UI Components
import { isEmptyString } from '@empathco/ui-components/src/helpers/strings';
// local imports
import { DEV_PLAN_DEFAULT_SKILLS, MAX_ITEMS, MAX_LOOKUP_OPTIONS } from '../config/params';
import {
  Maybe, AdvisorySkill, EmployeeAdvisorStatus, CohortSkillStatus, EmployeeActivity, EmployeeActivityStatus,
  SkillActivity, SkillActivityType, DevPlanTargetSkill, CohortSkill, HierarchyManager, Manager,
  SkillCategoriesQuery, SkillCategoriesQueryVariables,
  OrgsQuery, OrgsQueryVariables,
  CourseProvidersQuery, CourseProvidersQueryVariables,
  JobCategoriesQuery, JobCategoriesQueryVariables,
  SuccessStoriesQuery, SuccessStoriesQueryVariables,
} from '../graphql/types';
import { DevPlanEmployee, DevPlanProgress, EmployeeDevPlanProgress } from '../graphql/customTypes';
import { SKILL_LEVEL_FIRST, SKILL_LEVEL_MIN, SKILL_LEVEL_REGULAR, Skill, SkillLevel } from '../models/skill';
import { getSkillCurrentLevel } from '../helpers/models';

export const HEIRARCHY_OPTIONS: LazyQueryHookOptions = { fetchPolicy: 'cache-only' };

export const SUCCESS_STORIES_OPTIONS: QueryHookOptions<SuccessStoriesQuery, SuccessStoriesQueryVariables> = {
  variables: { limit: MAX_ITEMS }
};

export const LOOKUP_OPTIONS: QueryHookOptions<
  OrgsQuery | CourseProvidersQuery | JobCategoriesQuery,
  OrgsQueryVariables | CourseProvidersQueryVariables | JobCategoriesQueryVariables
> = { variables: { limit: MAX_LOOKUP_OPTIONS } };

export const SKILL_CATEGORIES_OPTIONS: QueryHookOptions<SkillCategoriesQuery, SkillCategoriesQueryVariables> = {
  variables: { limit: MAX_ITEMS }
};

export function updateCachedResults<T extends object, D extends object>(
  data: T | null,
  key: keyof T,
  updater: (result: D) => D
) {
  return {
    ...data || {},
    [key]: {
      ...data?.[key] || {},
      results: map((data?.[key] as unknown as { results?: D[] | null })?.results || [], updater)
    }
  } as T;
}

export function updateCachedResult<T extends object>(
  data: T | null,
  key: keyof T,
  updater: (result: T[keyof T]) => T[keyof T]
) {
  return {
    ...data || {},
    [key]: updater(data?.[key] as unknown as T[keyof T])
  } as T;
}

export const getCorortSkillsHasLevels = (cohortSkills?: CohortSkill[]) =>
  Boolean(find(cohortSkills, ({ status, skill_proficiency_level }: CohortSkill) =>
    status === CohortSkillStatus.required && // TODO: probably display desired skills as well?
    skill_proficiency_level && skill_proficiency_level !== SKILL_LEVEL_REGULAR)
  );

export const getDefaultTargetSkillIds = (targetedSkills?: DevPlanTargetSkill[] | null) => {
  const total = size(targetedSkills);
  const selected = filter(targetedSkills, 'is_selected');
  return size(selected) >= (total > DEV_PLAN_DEFAULT_SKILLS ? DEV_PLAN_DEFAULT_SKILLS : total) ? map(selected, 'id')
    : take(map([
        ...selected,
        ...reject(targetedSkills, 'is_selected')
      ], 'id'), DEV_PLAN_DEFAULT_SKILLS);
};

export const getTargetSkills = (targetedSkills?: DevPlanTargetSkill[] | null) => (
  (targetedSkills && filter(targetedSkills, 'is_selected')) ||
  undefined) as DevPlanTargetSkill[] | undefined;

export const getActualActivities = (
  skills?: (DevPlanTargetSkill | Skill)[] | null,
  selectedLevel?: SkillLevel | null,
  employees?: (DevPlanEmployee | DevPlanProgress | EmployeeDevPlanProgress)[] | null,
  selectedIds?: number[] | null,
  addSkills?: boolean
) => skills ? sortBy(values(
  transform(skills, (result, skill) => {
    const { id, activities, skill_proficiency_level } = skill;
    const { expected_level } = skill as Skill;
    const noSelectedLevel = isNil(selectedLevel);
    let minLevel: number | null = noSelectedLevel ? null : selectedLevel - SKILL_LEVEL_FIRST;
    const maxLevel = noSelectedLevel ? expected_level || skill_proficiency_level : selectedLevel;
    if (noSelectedLevel && employees) forEach(employees, ({ skills: emplSkills }) => {
      const emplSkill = find(emplSkills, ['id', id]);
      if (emplSkill) {
        const level = getSkillCurrentLevel(emplSkill);
        if (isNull(minLevel) || (level < minLevel)) {
          minLevel = level;
          if (minLevel <= SKILL_LEVEL_MIN) return false; // exit forEach if minLevel is 0 already
        }
        return undefined;
      }
      // at least one employee has no experience in this skill
      minLevel = SKILL_LEVEL_MIN;
      return false;
    });
    if (activities && !isNil(maxLevel) && (minLevel || SKILL_LEVEL_MIN) < maxLevel) {
      const startLevel = (minLevel || SKILL_LEVEL_MIN) + 1;
      // eslint-disable-next-line complexity
      forEach(activities, (skillActivity) => {
        const { id: activityId, code, activity_type, name, skill_proficiency_level: activityLevel } = skillActivity || {};
        if (
          !code || !activity_type || !name ||
          (activityLevel && (activityLevel < startLevel || activityLevel > maxLevel)) ||
          (selectedIds && indexOf(selectedIds, activityId) < 0)
        ) return;
        if (result[code]) {
          const activity = result[code];
          activity.count += 1;
          if (activity.max_level < (activityLevel || 0)) activity.max_level = activityLevel || 0;
          const skl = find(activity.skills, ['id', id]);
          if (skl) {
            if (skl.skill_proficiency_level < (activityLevel || 0)) skl.skill_proficiency_level = activityLevel || 0;
          } else activity.skills?.push({
            ...pick(skill, ['id', 'title', 'abbr']),
            skill_proficiency_level: activityLevel || 0
          });
        } else {
          result[code] = {
            ...omit(skillActivity, ['skill_proficiency_level']),
            activity_type: toLower(activity_type) as SkillActivityType,
            ...addSkills ? {
              skills: [
                {
                  ...pick(skill, ['id', 'title', 'abbr']),
                  skill_proficiency_level: activityLevel || 0
                }
              ]
            } : {},
            count: 1,
            max_level: activityLevel || 0
          };
        }
      });
    }
  }, {} as Record<string, SkillActivity & { count: number; max_level: number; }>)
), ['activity_type', 'max_level', 'count', 'name']) : undefined;

export const getEmployeeActivities = (employeeActivities?: EmployeeActivity[] | null): SkillActivity[] | null | undefined =>
  employeeActivities ? map(employeeActivities, ({ id, activity, status, skills, updated_at }) => ({
    ...activity,
    employee_activity_id: id,
    skills,
    is_selected: status === EmployeeActivityStatus.active,
    is_complete: status === EmployeeActivityStatus.completed,
    completed_at: status === EmployeeActivityStatus.completed ? updated_at : undefined
  })) : employeeActivities;

export const getAdvisoryState = (skills?: AdvisorySkill[] | null): {
  is_new: boolean;
  status?: Maybe<EmployeeAdvisorStatus>;
  status_updated_at?: string | null;
} => {
  let is_new = false;
  let status: EmployeeAdvisorStatus | null = null;
  let status_updated_at: Maybe<string> | undefined = null;
  forEach(skills, ({ is_new: isNew, status: skillStatus, status_updated_at: skillStatusUpdatedAt }) => {
    if (isNew) is_new = true;
    // priority: active, completed (not_requested is ignored unless it is the first status)
    if (!status ||
      // one skill is completed, but the other is not:
      (status === EmployeeAdvisorStatus.completed && skillStatus === EmployeeAdvisorStatus.active)
    ) {
      status = skillStatus || EmployeeAdvisorStatus.not_requested;
      status_updated_at = skillStatusUpdatedAt;
    } else if (!status_updated_at && skillStatusUpdatedAt && status === skillStatus) {
      status_updated_at = skillStatusUpdatedAt;
    }
  });
  return { is_new, status, status_updated_at };
};

export const transformHierarchy = (managers?: readonly HierarchyManager[] | null): Manager[] | null | undefined => {
  if (!managers) return null;
  if (size(managers) < 1) return [];
  const level1 = managers?.[0]?.level || 1;
  const { orgHierarchy: hierarchy, topLevel: siblingsIdx } = transform(
    managers,
    ({ orgHierarchy, topLevel, parents }, { code, full_name, level, org_id, org_title }) => {
      // ignore level=1 and consider level=2 as top level nodes
      if (level && level > level1) {
        const title = startCase(toLower(full_name));
        orgHierarchy.push({
          id: code,
          title: org_title && !isEmptyString(org_title) ? `${org_title} – ${title}` : title,
          level: level - level1,
          org_id,
          direct_reports_idx: [],
          parent_idx: parents[level - level1] ?? -1,
          siblings_idx: null,
          topId: null,
          topOrgId: null,
          topName: null,
          managers: null
        });
        const index = orgHierarchy.length - 1;
        parents[level - level1 + 1] = index;
        (level <= level1 + 1 ? topLevel : orgHierarchy[parents[level - level1]].direct_reports_idx)?.push(index);
      }
    },
    {
      orgHierarchy: [] as Manager[],
      topLevel: [] as number[],
      parents: [] as number[]
    }
  );
  const firsItem = head(hierarchy);
  if (firsItem) {
    firsItem.siblings_idx = siblingsIdx;
    firsItem.topId = managers?.[0]?.code;
    firsItem.topOrgId = managers?.[0]?.org_id;
    firsItem.topName = (level1 > 1 && (managers?.[0]?.org_title || startCase(toLower(managers?.[0]?.full_name)))) || null;
    firsItem.managers = level1 === 1 ? managers as HierarchyManager[] : null;
  }
  return hierarchy;
}
