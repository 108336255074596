import { forwardRef, memo, useCallback, useMemo, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
// Material UI imports
import { type Variant } from '@mui/material/styles/createTypography';
import Box from '@mui/material/Box';
// Material Icon imports
import ZoomInIcon from '@mui/icons-material/ZoomIn';
// EmPath UI Components
import { injectParams } from '@empathco/ui-components/src/helpers/path';
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
import AccountCircleAlt from '@empathco/ui-components/src/icons/AccountCircleAlt';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import { AdminEmployee, AdminUser, Employee } from '../graphql/types';
import {
  TalentEmployeeObject, DevPlanEmployee, RedeploymentEmployee, TalentEmployeeDetails, TalentManager
} from '../graphql/customTypes';
import useCustomerSettings from '../config/customer';
// SCSS imports
import { employeeLink } from './EmployeeName.module.scss';

type EmployeeNameProps = {
  employee: Employee |
    DevPlanEmployee | RedeploymentEmployee |
    TalentEmployeeObject | TalentManager | TalentEmployeeDetails |
    AdminEmployee | AdminUser;
  fullName?: string | ReactNode | ReactNode[];
  route?: string | null;
  variant?: Variant | 'inherit';
  admin?: boolean;
  supervisor?: boolean;
  manager?: boolean;
  onClick?: ((employee: TalentEmployeeObject) => void) | ((user: AdminUser) => void);
  disabled?: boolean;
};

const EmployeeNamePropTypes = {
  employee: PropTypes.object.isRequired as Validator<Employee |
    DevPlanEmployee | RedeploymentEmployee |
    TalentEmployeeObject | TalentManager | TalentEmployeeDetails |
    AdminEmployee | AdminUser>,
  fullName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]) as Validator<string | ReactNode | ReactNode[]>,
  route: PropTypes.string,
  variant: PropTypes.string as Validator<Variant | 'inherit'>,
  admin: PropTypes.bool,
  supervisor: PropTypes.bool,
  manager: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

const EmployeeName = forwardRef<HTMLAnchorElement, EmployeeNameProps>(({
  employee,
  fullName,
  route,
  variant,
  admin = false,
  supervisor = false,
  manager = false,
  onClick,
  disabled = false
}, ref) => {
  const { getEmployeeContactUrl } = useCustomerSettings();
  const { email, first_name, last_name } = employee;
  const { code } = employee as (DevPlanEmployee | RedeploymentEmployee |
    TalentEmployeeObject | TalentManager | TalentEmployeeDetails |
    AdminEmployee);
  const { is_profile_accessible } = employee as (DevPlanEmployee | RedeploymentEmployee | TalentEmployeeObject);

  const handleClick = useCallback(() => (
    onClick as ((employee: TalentEmployeeObject | AdminUser) => void)
  )?.(employee as TalentEmployeeObject), [employee, onClick]);

  const [link, href] = useMemo(() => route && is_profile_accessible
    ? [onClick || !code ? undefined : injectParams(route, { employee_id: code }), undefined]
    : [undefined, onClick ? undefined : getEmployeeContactUrl(code, email)],
    [code, email, is_profile_accessible, route, onClick, getEmployeeContactUrl]
  );

  return (manager && (
    <StandardLink
        ref={ref}
        variant={variant}
        to={disabled ? undefined : link}
        href={disabled ? undefined : href}
    >
      {fullName || getFullName(first_name, last_name, code)}
    </StandardLink>
  )) || (supervisor && (
    <Box ref={ref} display="flex" alignItems="center">
      <Box pr={1} display="flex" alignItems="center" color="misc.selectedBorder">
        <AccountCircleAlt fontSize="large" color="inherit"/>
      </Box>
      <StandardLink
          variant={variant}
          to={disabled ? undefined : link}
          href={disabled ? undefined : href}
      >
        {fullName || getFullName(first_name, last_name, code)}
      </StandardLink>
    </Box>
  )) || (
    <StandardLink
        ref={ref}
        variant={variant}
        to={disabled ? undefined : link}
        href={disabled ? undefined : href}
        onClick={onClick && !disabled ? handleClick : undefined}
        className={admin ? undefined : employeeLink}
    >
      {admin ? fullName || getFullName(first_name, last_name, code) : (
        <>
          <Box pr={0.5}>
            {fullName || getFullName(first_name, last_name, code)}
          </Box>
          <ZoomInIcon color="inherit"/>
        </>
      )}
    </StandardLink>
  );
});

EmployeeName.displayName = 'EmployeeName';

EmployeeName.propTypes = EmployeeNamePropTypes;

export default memo(EmployeeName);
