import { forwardRef, memo, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import isNil from 'lodash/isNil';
import toSafeInteger from 'lodash/toSafeInteger';
import { Link as RouterLink } from 'react-router-dom';
import { FormattedMessage, FormattedNumber } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
// EmPath UI Components
import { shape } from '@empathco/ui-components/src/styles/themeOptions';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import FetchFailedAlert from '@empathco/ui-components/src/elements/FetchFailedAlert';
import LoadingPlaceholder from '@empathco/ui-components/src/elements/LoadingPlaceholder';
// local imports
import { RouterLinkState } from '../helpers/routerParams';
// SCSS imports
import { item, card, newCountMsg, button } from './DashboardCard.module.scss';

type DashboardCardProps = {
  children: ReactNode | ReactNode[];
  title: string;
  newMessage: string;
  link: string;
  linkState?: RouterLinkState;
  count?: number;
  newCount?: number;
  altCount?: number;
  hasChart?: boolean;
  wide?: boolean;
  pending?: boolean | null;
  failed?: boolean | null;
}

const DashboardCardPropTypes = {
  // React built-in
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node.isRequired).isRequired,
    PropTypes.node.isRequired
  ]).isRequired as Validator<ReactNode | ReactNode[]>,
  // attributes
  title: PropTypes.string.isRequired,
  newMessage: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  linkState: PropTypes.object,
  count: PropTypes.number,
  newCount: PropTypes.number,
  altCount: PropTypes.number,
  hasChart: PropTypes.bool,
  wide: PropTypes.bool,
  pending: PropTypes.bool,
  failed: PropTypes.bool
};

// eslint-disable-next-line complexity
const DashboardCard = forwardRef<HTMLDivElement, DashboardCardProps>(({
  children,
  title,
  newMessage,
  link,
  linkState,
  count,
  newCount,
  altCount,
  hasChart = false,
  pending = false,
  failed = false,
  wide = false
}, ref) => {
  const hasNewCount = !isNil(newCount) && newCount >= 1;
  const hasAltCount = !isNil(altCount) && altCount >= 1;
  const columnLayout = hasNewCount && hasAltCount;
  const newCnt = hasNewCount || hasAltCount ? (
    <Typography variant="subtitle2" className={newCountMsg}>
      <FormattedMessage
          id={newMessage}
          values={{
            count: toSafeInteger(newCount),
            altCount: toSafeInteger(altCount)
          }}
      />
    </Typography>
  ) : ' ';
  return (
    <Grid
        ref={ref}
        item
        xs={12}
        sm={10}
        md={6}
        lg={wide ? undefined : 4}
        container
        alignItems="stretch"
        className={item}
    >
      <Card elevation={9} className={card}>
        {(failed && <FetchFailedAlert/>) ||
        (pending && <LoadingPlaceholder fullHeight/>) || (
          <>
            <Box
                p={columnLayout ? undefined : 3}
                px={columnLayout ? 3 : undefined}
                pt={columnLayout ? 3 : undefined}
                color="text.label"
                bgcolor="background.paper"
                display="flex"
            >
              <BoxTypography flex="1 1 0" pr={1} variant="subtitle1">
                <FormattedMessage id={title} defaultMessage={title} values={{ br: <br/> }}/>
              </BoxTypography>
              {!isNil(count) && !isNil(newCount) ? (
                <Box flex="1 1 0" display="flex" flexDirection="column" alignItems="flex-end">
                  <Typography variant="h2">
                    <FormattedNumber value={toSafeInteger(count)}/>
                  </Typography>
                  {!columnLayout && newCnt}
                </Box>
              ) : undefined}
            </Box>
            {columnLayout ? (
              <Box px={3} pb={3} flex="1 1 0" display="flex" justifyContent="flex-end">
                {newCnt}
              </Box>
            ) : undefined}
            <Box
                flex={hasChart ? '1 1 auto' : '1 0 0'}
                display={hasChart ? 'flex' : undefined}
                position={hasChart ? 'relative' : undefined}
                alignItems={hasChart ? 'center' : undefined}
                justifyContent={hasChart ? 'center' : undefined}
            >
              {children}
            </Box>
            <Box px={10} py={5} bgcolor="background.button" border={shape.thinBorderWidth} borderColor="delimiterColor">
              <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  fullWidth
                  className={button}
                  component={RouterLink}
                  to={link}
                  state={linkState}
              >
                <FormattedMessage id="board.view_all"/>
              </Button>
            </Box>
          </>
        )}
      </Card>
    </Grid>
  );
});

DashboardCard.displayName = 'DashboardCard';

DashboardCard.propTypes = DashboardCardPropTypes;

export default memo(DashboardCard);
