import { memo, useMemo, type FunctionComponent, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import map from 'lodash/map';
import size from 'lodash/size';
import round from 'lodash/round';
import floor from 'lodash/floor';
import isNil from 'lodash/isNil';
import toSafeInteger from 'lodash/toSafeInteger';
import { FormattedNumber, FormattedMessage } from 'react-intl';
// Material UI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableSortLabel from '@mui/material/TableSortLabel';
import Skeleton from '@mui/material/Skeleton';
// EmPath UI Components
import SortArrow from '@empathco/ui-components/src/icons/SortArrow';
import InfoButton from '@empathco/ui-components/src/elements/InfoButton';
import CardSection from '@empathco/ui-components/src/elements/CardSection';
import DataTable from '@empathco/ui-components/src/elements/DataTable';
// local imports
import { RedeploymentSort, SortDirection } from '../graphql/types';
import { RedeploymentEmployee, TalentEmployeeObject } from '../graphql/customTypes';
import { REDEPLOYMENT_SORT_DIRECTIONS, DEFAULT_REDEPLOYMENT_SORT_DIRECTION } from '../constants/redeployment';
import useModels from '../helpers/models';
import EmployeeName from '../elements/EmployeeName';
import { hasPagination, PaginationControlsComponent } from '../v3/PaginationControls';

type RedeploymentEmployeesTableProps = {
  employees?: RedeploymentEmployee[];
  sortOrder?: RedeploymentSort | null;
  direction?: SortDirection | null;
  changeSort: (param: { sortOrder: RedeploymentSort; direction: SortDirection; }) => void;
  onClick?: (employee: RedeploymentEmployee) => void;
  disabled?: boolean | null;
  pending?: boolean | null;
  failed?: boolean | null;
  pendingEmployeeId?: number;
  pendingJobCode?: string;
  filters?: ReactNode | ReactNode[];
  pagination?: ReactNode | ReactNode[];
};

const RedeploymentEmployeesTablePropTypes = {
  // attributes
  employees: PropTypes.array as Validator<RedeploymentEmployee[]>,
  sortOrder: PropTypes.string as Validator<RedeploymentSort>,
  direction: PropTypes.string as Validator<SortDirection>,
  changeSort: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  pending: PropTypes.bool,
  failed: PropTypes.bool,
  pendingEmployeeId: PropTypes.number,
  pendingJobCode: PropTypes.string,
  filters: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>,
  pagination: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]) as Validator<ReactNode | ReactNode[]>
};

const RedeploymentEmployeesTable: FunctionComponent<RedeploymentEmployeesTableProps> = ({
  employees,
  sortOrder,
  direction,
  changeSort,
  onClick,
  disabled = false,
  pending = false,
  failed = false,
  pendingEmployeeId,
  pendingJobCode,
  filters,
  pagination
}) => {
  const { getLocationStr } = useModels();
  const withPagination = hasPagination(pagination as PaginationControlsComponent);

  const [sortByName, sortByLocation, sortByManager, /* sortByJob, sortByMatchRate, */ sortByYears, sortByRating] = useMemo(
    () => map(REDEPLOYMENT_SORT_DIRECTIONS, (sorting: RedeploymentSort) => DEFAULT_REDEPLOYMENT_SORT_DIRECTION[sorting]
      ? () => changeSort({
          sortOrder: sorting,
          direction: sortOrder === sorting
            ? (direction === SortDirection.ascending && SortDirection.descending) || SortDirection.ascending
            : DEFAULT_REDEPLOYMENT_SORT_DIRECTION[sorting] as SortDirection
        })
      : undefined
  ), [changeSort, direction, sortOrder]);

  const employeesCount = size(employees);
  const actionsDisabled = disabled || pending || !employees || employeesCount < 1;

  const titles = useMemo(() => map([
    { label: 'hr.redeployment.column.employee', id: RedeploymentSort.employee, handler: sortByName },
    { label: 'hr.redeployment.column.location', id: RedeploymentSort.location, handler: sortByLocation },
    { label: 'hr.redeployment.column.manager', id: RedeploymentSort.manager, handler: sortByManager },
    { label: 'hr.redeployment.column.job', id: RedeploymentSort.job_title, handler: undefined /* sortByJob */ },
    { label: 'hr.redeployment.column.match_rate', id: RedeploymentSort.match_rate, handler: undefined /* sortByMatchRate */ },
    { label: 'hr.redeployment.column.years', id: RedeploymentSort.years_on_job, handler: sortByYears },
    { label: 'hr.redeployment.column.rating', id: RedeploymentSort.performance_rating, handler: sortByRating }
  ], ({ id, label, handler }) => {
    const isMatchRate = id === RedeploymentSort.match_rate;
    const labelElement = id && handler ? (
      <TableSortLabel
          key={isMatchRate ? undefined : label}
          direction={(sortOrder === id && direction ? direction : DEFAULT_REDEPLOYMENT_SORT_DIRECTION[id]) ===
            SortDirection.ascending ? 'asc' : 'desc'}
          active={sortOrder === id}
          onClick={handler}
          disabled={actionsDisabled}
          IconComponent={SortArrow}
      >
        <FormattedMessage id={label}/>
      </TableSortLabel>
    ) : <FormattedMessage key={isMatchRate ? undefined : label} id={label}/>;
    return isMatchRate ? (
      <Box key={label} display="flex" alignItems="center">
        <InfoButton small help="hr.redeployment.column.match_rate.help"/>
        {labelElement}
      </Box>
    ) : labelElement;
  }), [
    actionsDisabled, sortOrder, direction,
    sortByName, sortByLocation, sortByManager, /* sortByJob, sortByMatchRate, */ sortByYears, sortByRating
  ]);

  const rows = useMemo(() => map(employees, (employee) => {
    const { id, location, manager, job, years_on_job, performance_rating } = employee || {};
    const pendingJob = pendingEmployeeId && pendingJobCode && pendingEmployeeId === id && job?.code !== pendingJobCode;
    return {
      selected: false,
      values: [
        /* eslint-disable react/jsx-key */
        <EmployeeName
            employee={employee}
            onClick={onClick as ((employee: TalentEmployeeObject) => void) | undefined}
            disabled={disabled || pending ? true : undefined}
        />,
        getLocationStr(location) || <FormattedMessage id="employees.not_available"/>,
        manager ? (
          <EmployeeName
              employee={manager}
              manager
              disabled={disabled || pending ? true : undefined}
          />
        ) : '—',
        pendingJob ? (
          <Skeleton variant="text" width="10rem"/>
        ) : job?.title || '—',
        pendingJob ? (
          <Box display="flex" alignItems="center" justifyContent="center">
            <Skeleton variant="text" width="2rem"/>
          </Box>
        ) : (
          <Typography variant="h5">
            {/* eslint-disable-next-line react/style-prop-object */}
            <FormattedNumber value={toSafeInteger(job?.match_rate) / 100} style="percent" minimumFractionDigits={0}/>
          </Typography>
        ),
        isNil(years_on_job) ? '—' : <FormattedNumber value={floor(years_on_job)}/>,
        isNil(performance_rating) ? '—' : <FormattedNumber value={round(performance_rating)}/>
        /* eslint-enable react/jsx-key */
      ]
    };
  }), [employees, disabled, pending, pendingEmployeeId, pendingJobCode, onClick, getLocationStr]);

  return (
    <>
      {filters ? (
        <CardSection top>
          {filters}
        </CardSection>
      ) : undefined}
      <CardSection>
        <DataTable
            tableSize="medium"
            titles={titles}
            empty="hr.talentfinder.empty"
            data={rows}
            pending={pending || !employees}
            failed={failed}
            lastLeftAlignedTitle={3}
        />
      </CardSection>
      {withPagination ? (
        <CardSection flex bottom>
          {pagination}
        </CardSection>
      ) : pagination}
    </>
  );
};

RedeploymentEmployeesTable.propTypes = RedeploymentEmployeesTablePropTypes;

export default memo(RedeploymentEmployeesTable);
