import { forwardRef, memo, useCallback, useContext, useMemo, type ReactNode } from 'react';
import PropTypes, { type Validator } from 'prop-types';
import startsWith from 'lodash/startsWith';
import { FormattedMessage } from 'react-intl';
// EmPath UI Components
import type { BreadcrumbItem } from '@empathco/ui-components/src/types';
import BoxTypography from '@empathco/ui-components/src/mixins/BoxTypography';
import { getFullName } from '@empathco/ui-components/src/helpers/strings';
import { mapChunks } from '@empathco/ui-components/src/helpers/intl';
import AppBar, {
  AppBarVariantProp, APPBAR_EMPTY, APPBAR_FULL, type AppBarVariant
} from '@empathco/ui-components/src/elements/AppBar';
import StandardLink from '@empathco/ui-components/src/elements/StandardLink';
// local imports
import { isAdminOnly } from '../models/user';
import CustomerLogo from '../customers/CustomerLogo';
import { CustomerTicker } from '../config/customer';
import usePathConfig, { PATH_ADMIN_EMPLOYEES, PATH_JOB, PATH_SITE_ADMIN } from '../config/paths';
import { getAuthToken, setAuthToken } from '../helpers/storage';
import useRedirects from '../helpers/redirect';
import { GlobalContext } from '../context/global';
import SkillsAndJobsSearch from '../v3/SkillsAndJobsSearch';
import NotificationsIcon from '../widgets/NotificationsIcon';
import UserDropdown from '../widgets/UserDropdown';
import AppBarTicker from '../widgets/AppBarTicker';
// SCSS imports
import { banner } from './CustomizedAppBar.module.scss';

type CustomizedAppBarProps = {
  admin?: boolean;
  path?: string;
  breadcrumbs?: BreadcrumbItem[];
  drawerOpen?: boolean;
  toggleDrawer: () => void;
  variant?: AppBarVariant;
  // for Storybook only
  logo?: ReactNode;
  tickers?: CustomerTicker[];
};

const CustomizedAppBarPropTypes = {
  // attributes
  admin: PropTypes.bool,
  path: PropTypes.string,
  breadcrumbs: PropTypes.array,
  drawerOpen: PropTypes.bool,
  toggleDrawer: PropTypes.func.isRequired,
  variant: AppBarVariantProp,
  logo: PropTypes.node as Validator<ReactNode>,
  tickers: PropTypes.array
};

const CustomizedAppBar = forwardRef<HTMLDivElement, CustomizedAppBarProps>(({
  admin = false,
  path = '',
  breadcrumbs: nextBreadcrumbs,
  drawerOpen = false,
  toggleDrawer,
  variant = 'full',
  logo,
  tickers
}, ref) => {
  const { BREADCRUMBS } = usePathConfig();
  const { getHomePath } = useRedirects();
  const { user: { data: user }, token } = useContext(GlobalContext);
  const { first_name, last_name, code } = user || {};
  const homePath = getHomePath(user);

  const breadcrumbs = useMemo(() => [
    ...BREADCRUMBS[path] || [],
    ...nextBreadcrumbs || []
  ], [path, nextBreadcrumbs, BREADCRUMBS]);

  const savedAdminToken = getAuthToken(true);
  const hasBanner = Boolean(user) && startsWith(token as string, 'impersonation_');
  const atJobScreen = path === PATH_JOB;
  const hasTicker = path === homePath || atJobScreen;

  const handleExitImpersonation = useCallback(() => {
    // set saved admin auth token as current token
    setAuthToken(savedAdminToken);
    // clear saved admin token
    setAuthToken('', true);
    // redirect to Admin Employees screen
    window.location.assign(PATH_ADMIN_EMPLOYEES);
  }, [savedAdminToken]);

  const link = useCallback((chunks?: ReactNode | ReactNode[] | null): ReactNode => (
    <StandardLink
        dark
        withoutTarget
        onClick={savedAdminToken ? handleExitImpersonation : undefined}
        href={savedAdminToken ? undefined : PATH_SITE_ADMIN}
    >
      {mapChunks(chunks)}
    </StandardLink>
  ), [savedAdminToken, handleExitImpersonation]);

  return (
    <AppBar
        ref={ref}
        tickers={variant === APPBAR_FULL && (hasBanner || hasTicker) ? (
          <>
            {hasBanner ? (
              <BoxTypography
                  py={0.5}
                  px={1.75}
                  textAlign="center"
                  variant="body1"
                  className={banner}
              >
                <FormattedMessage
                    id="admin.message.impersonation"
                    values={{ code, link, name: getFullName(first_name, last_name, code) }}
                />
              </BoxTypography>
            ) : undefined}
            {hasTicker ? <AppBarTicker atJobScreen={atJobScreen} tickers={tickers}/> : undefined}
          </>
        ) : undefined}
        path={homePath}
        breadcrumbs={breadcrumbs}
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        variant={variant}
        logo={logo || <CustomerLogo/>}
    >
      {variant !== APPBAR_EMPTY && (
        <>
          {isAdminOnly(user) ? undefined : (
            <>
              {admin ? undefined : <SkillsAndJobsSearch/>}
              <NotificationsIcon/>
            </>
          )}
          <UserDropdown disableLogout={hasBanner}/>
        </>
      )}
    </AppBar>
  );
});

CustomizedAppBar.displayName = 'CustomizedAppBar';

CustomizedAppBar.propTypes = CustomizedAppBarPropTypes;

export default memo(CustomizedAppBar);
